export const activeData = [
    {
        id: "1",
        fromMailName: 'Venkata Sainath Kadiyala',
        fromMail: 'vsk@mitresource.com',
        date: '12 March 2023',
        time: '4:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Company Incorporation Certificate',
        fileType: 'PDF',
        subContent: 'PAN',
        expireDate: '28 March 2023',
        expireTime: '08:00 AM',
        refID: 'DFXLFILNO-2320A1242',
        security: 'yes',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sampletest.pdf'

        // documtPath:''
    },
    {
        id: "2",
        fromMailName: 'Sushma Maganti',
        fromMail: 'SM@mitresource.com',
        date: '12 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Orgination important files details',
        fileType: 'Word',
        subContent: 'Employee',
        expireDate: '15 March 2023',
        expireTime: '10:00 AM',
        security: 'no',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },
    {
        id: "3",
        fromMailName: 'Rajkumar Nagaboyina',
        fromMail: 'RN@mitresource.com',
        date: '02 February 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Company important files details',
        fileType: 'PDF',
        subContent: 'Tax',
        expireDate: '16 March 2023',
        expireTime: '11:00 AM',
        security: 'no',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sampletest.pdf'

    },

    {
        id: "4",
        fromMailName: 'Anil P',
        fromMail: 'AP@mitresource.com',
        date: '10 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'House important files details',
        fileType: 'Word',
        subContent: 'Land',
        expireDate: '21 March 2023',
        expireTime: '10:00 AM',
        security: 'yes',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },
    {
        id: "5",
        fromMailName: 'Venkata Sainath Kadiyala',
        fromMail: 'VSK@mitresource.com',
        date: '11 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Orgination important files details',
        fileType: 'Word',
        subContent: 'Aggrement Documents',
        expireDate: '14 March 2023',
        expireTime: '10:00 AM',
        security: 'no',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },
    {
        id: "6",
        fromMailName: 'Anji R',
        fromMail: 'AR@mitresource.com',
        date: '11 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Orgination important files details',
        fileType: 'PDF',
        subContent: 'Person',
        expireDate: '20 March 2023',
        expireTime: '10:00 AM',
        security: 'no',

        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sampletest.pdf'

    },

    {
        id: "7",
        fromMailName: 'Venkata Sainath Kadiyala',
        fromMail: 'VSK@mitresource.com',
        date: '05 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Land Details',
        fileType: 'Word',
        subContent: 'Information',
        expireDate: '23 March 2023',
        expireTime: '10:00 AM',
        security: 'yes',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },

    {
        id: "8",
        fromMailName: 'Venkatesh M',
        fromMail: 'VM@mitresource.com',
        date: '09 March 2023',
        time: '4:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Company Incorporation Certificate',
        fileType: 'PDF',
        subContent: 'Loan',
        expireDate: '27 March 2023',
        expireTime: '10:00 AM',
        refID: 'DFXLFILNO-2320A1202',
        security: 'yes',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sampletest.pdf'

        // documtPath:''
    },
    {
        id: "9",
        fromMailName: 'Upendra P',
        fromMail: 'UP@mitresource.com',
        date: '05 February 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Orgination important files details',
        fileType: 'Word',
        subContent: 'Accounts',
        expireDate: '25 March 2023',
        expireTime: '10:00 AM',
        security: 'no',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },
    {
        id: "10",
        fromMailName: 'Madhu S',
        fromMail: 'MS@mitresource.com',
        date: '05 February 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Company important files details',
        fileType: 'PDF',
        subContent: 'Accounts',
        expireDate: '15 March 2023',
        expireTime: '10:00 AM',
        security: 'no',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sampletest.pdf'

    },

    {
        id: "11",
        fromMailName: 'Madhu S',
        fromMail: 'MS@mitresource.com',
        date: '10 February 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'House important files details',
        fileType: 'Word',
        subContent: 'Land',
        expireDate: '28 March 2023',
        expireTime: '10:00 AM',
        security: 'yes',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },
    {
        id: "12",
        fromMailName: 'Venkatesh M',
        fromMail: 'VM@mitresource.com',
        date: '18 February 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Office files details',
        fileType: 'Word',
        subContent: 'Attendance',
        expireDate: '01 April 2023',
        expireTime: '10:00 AM',
        security: 'no',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },
    {
        id: "13",
        fromMailName: 'Venkatesh M',
        fromMail: 'VM@mitresource.com',
        date: '25 February 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Company important files details',
        fileType: 'PDF',
        subContent: 'TDS',
        expireDate: '18 March 2023',
        expireTime: '10:00 AM',
        security: 'no',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sampletest.pdf'

    },

    {
        id: "14",
        fromMailName: 'Akhil A',
        fromMail: 'AA@mitresource.com',
        date: '03 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'House important files details',
        fileType: 'Word',
        subContent: 'Accounts',
        expireDate: '13 March 2023',
        expireTime: '10:00 AM',
        security: 'yes',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },
    {
        id: "15",
        fromMailName: 'Raghu L',
        fromMail: 'RL@mitresource.com',
        date: '11 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Incorporation Certificate',
        fileType: 'PDF',
        subContent: 'Paper',
        expireDate: '18 March 2023',
        expireTime: '10:00 AM',
        security: 'no',

        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sampletest.pdf'

    },
    {
        id: "16",
        fromMailName: 'Arun S',
        fromMail: 'AS@mitresource.com',
        date: '11 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Office important files details',
        fileType: 'Word',
        subContent: 'Doc',
        expireDate: '23 March 2023',
        expireTime: '10:00 AM',
        refID: 'DFXLFILNO-2320A1201',
        security: 'yes',

        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },
    {
        id: "17",
        fromMailName: 'Arun S',
        fromMail: 'AS@mitresource.com',
        date: '01 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Incorporation Certificate',
        fileType: 'PDF',
        subContent: 'TDS',
        expireDate: '13 March 2023',
        expireTime: '10:00 AM',
        security: 'no',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sampletest.pdf'

    },
    {
        id: "18",
        fromMailName: 'Satya A',
        fromMail: 'AM@mitresource.com',
        date: '12 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Car important files details',
        fileType: 'Word',
        subContent: 'Loan',
        expireDate: '17 March 2023',
        expireTime: '10:00 AM',
        refID: 'DFXLFILNO-2320A1201',
        security: 'yes',

        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },
    {
        id: "19",
        fromMailName: 'Satya A',
        fromMail: 'AM@mitresource.com',
        date: '09 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Bike important files details',
        fileType: 'Word',
        subContent: 'PAN',
        expireDate: '20 March 2023',
        expireTime: '10:00 AM',
        refID: 'DFXLFILNO-2320A1201',
        security: 'yes',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },

];

export const expireDate = [
    {
        id: "101",
        fromMailName: 'Ramesh L',
        fromMail: 'RL@mitresource.com',
        date: '09 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Incorporation Certificate',
        fileType: 'PDF',
        subContent: 'CIN',
        expireDate: '10 March 2023',
        expireTime: '10:00 AM',
        security: 'no',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sampletest.pdf'

    },
    {
        id: "102",
        fromMailName: 'Ramesh L',
        fromMail: 'RL@mitresource.com',
        date: '09 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Office important files details',
        fileType: 'Word',
        subContent: 'PAN',
        expireDate: '11 March 2023',
        expireTime: '10:00 AM',
        refID: 'DFXLFILNO-2320A1201',
        security: 'yes',

        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },
    {
        id: "103",
        fromMailName: 'Ramesh L',
        fromMail: 'RL@mitresource.com',
        date: '22 February 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Company important files details',
        fileType: 'Word',
        subContent: 'TAN',
        expireDate: '27 February 2023',
        expireTime: '10:00 AM',
        security: 'no',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },
    {
        id: "104",
        fromMailName: 'Lokesh P',
        fromMail: 'LP@mitresource.com',
        date: '01 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Orgination important files details',
        fileType: 'Word',
        subContent: 'CIN',
        expireDate: '05 March 2023',
        expireTime: '10:00 AM',
        security: 'no',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },
    {
        id: "105",
        fromMailName: 'Raju D',
        fromMail: 'RN@mitresource.com',
        date: '20 February 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Company important files details',
        fileType: 'PDF',
        subContent: 'CIN',
        expireDate: '25 February 2023',
        expireTime: '10:00 AM',
        security: 'no',

        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sampletest.pdf'

    },

    {
        id: "106",
        fromMailName: 'Raju D',
        fromMail: 'RD@mitresource.com',
        date: '05 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'House important files details',
        fileType: 'Word',
        subContent: 'TAN',
        expireDate: '07 March 2023',
        expireTime: '10:00 AM',
        security: 'yes',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },

    {
        id: "107",
        fromMailName: 'Raju D',
        fromMail: 'RD@mitresource.com',
        date: '10 February 2023',
        time: '4:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Incorporation Certificate',
        fileType: 'PDF',
        subContent: 'NDP',
        expireDate: '25 February 2023',
        expireTime: '10:00 AM',
        refID: 'DFXLFILNO-2320A1202',
        security: 'yes',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sampletest.pdf'

        // documtPath:''
    },
    {
        id: "108",
        fromMailName: 'Suri T',
        fromMail: 'ST@mitresource.com',
        date: '13 February 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Orgination important files details',
        fileType: 'Word',
        subContent: 'Loan',
        expireDate: '26 february 2023',
        expireTime: '10:00 AM',
        security: 'no',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },
    {
        id: "109",
        fromMailName: 'Raja J',
        fromMail: 'RN@mitresource.com',
        date: '21 February 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Company important files details',
        fileType: 'PDF',
        subContent: 'CIN',
        expireDate: '08 March 2023',
        expireTime: '10:00 AM',
        security: 'no',

        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sampletest.pdf'

    },

    {
        id: "110",
        fromMailName: 'Sandeep N',
        fromMail: 'AP@mitresource.com',
        date: '14 February 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'House important files details',
        fileType: 'Word',
        subContent: 'CIN',
        expireDate: '06 March 2023',
        expireTime: '10:00 AM',
        security: 'yes',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },
    {
        id: "111",
        fromMailName: 'Anand M',
        fromMail: 'SM@mitresource.com',
        date: '01 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'Office List',
        fileType: 'Word',
        subContent: 'CIN',
        expireDate: '08 March 2023',
        expireTime: '10:00 AM',
        security: 'no',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sample.docx'

    },
    {
        id: "112",
        fromMailName: 'Kishore K',
        fromMail: 'RN@mitresource.com',
        date: '03 March 2023',
        time: '10:30 AM',
        toMailName: 'Kumar Ramanathan',
        toMail: 'kumar.ramanathan@datafabricx.com',
        sub: 'House Loan',
        fileType: 'PDF',
        subContent: 'CIN',
        expireDate: '06 March 2023',
        expireTime: '10:00 AM',
        security: 'no',
        refID: 'DFXLFILNO-2320A1201',
        documtPath: 'C:/Users/mitla/Desktop/mitdfxfileshare_ui/src/components/Inbox/Documents/sampletest.pdf'

    },



];


