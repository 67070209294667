import React from "react";
import { useState, useEffect, useRef, useReducer } from 'react';
import { Link } from "react-router-dom";

import ReactInputVerificationCode from "react-input-verification-code";
import { useHistory } from "react-router-dom";



import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import Logo from '../../images/dfxFileLogo.png';

import styles from "./loginPage.module.css";




function VerificationNumber() {
    const [formErrors, setFormErrors] = useState({});
    const [value, setValue] = useState({});
    const history = useHistory();


    const [state, dispatch] = useReducer();

    const handleVerificationCodeChange = (value) => {
        setValue(value);
        setFormErrors({})
    };

    const handleSubmit = (e) => {
        console.log(value, "val")
        e.preventDefault();
        const errors = validateForm(value);
        setFormErrors(errors)
        console.log(errors, "err")
        console.log(errors !== null, "tr")

        if (!errors.verificationCode) {
            console.log("567")
            history.push("/dashboard");

        }

    };

    const validateForm = (data) => {
        const errors = {};

        if (!data) {
            errors.verificationCode = "Enter Verification code";
        } else if (data.length < 6) {
            errors.verificationCode = "Enter please 6 numbers"
        }
        return errors;
    };



    return (
        <React.Fragment>
            <div className="container-fluid logPagehightOverFlow p-0">
                <div className="row m-0">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0">
                        <div className="position-relative">
                            <div className={styles.login_image} >
                                <div className={styles.imgDFXlogo}>
                                    <img src={Logo} style={{ width: '100px' }}></img>
                                    <div className={styles.logInName}>Giving Your <spn style={{ color: '#2F9FD9' }}> Data </spn> the care and Security it needs.</div>


                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">

                        <div className={styles.loginpageTopSet} >
                            <div className={styles.logName}>Verify your Account</div>
                            <form id="form1" method="post">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-2">
                                    </div>

                                    <div className="col-sm-12 col-md-12 col-lg-8">
                                        <div className="text-center mt-3">
                                            <label className={styles.inputlableNames}>Enter code</label>
                                            <div className={styles.verifyCustomStyle}>
                                                <ReactInputVerificationCode
                                                    length={6}
                                                    autoFocus
                                                    placeholder=""
                                                    onChange={handleVerificationCodeChange}
                                                />
                                            </div>


                                        </div>
                                        <div className="mt-2">
                                            {formErrors.verificationCode && <span style={{ color: 'red' }}>{formErrors.verificationCode}</span>}
                                        </div>
                                    </div>

                                </div>
                                <div className="mt-5">
                                    <ButtonComponent className={styles.logbtn} onClick={handleSubmit} cssClass='e-info' > Continue </ButtonComponent>
                                </div>
                            </form>
                            <div class="mb-4 d-flex justify-content-center align-items-center position-relative">
                                <div class="position-absolute  px-3" style={{ "color": "#828282" }}> Did not receive the code ? <spn style={{ color: '#2F9FD9' }}> <Link className="" to="">Resend</Link> </spn> </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default VerificationNumber;