import React from "react";
import { useState, useEffect, useRef, useReducer } from 'react';
import { Link } from "react-router-dom";
import { TextBoxComponent, FormValidator } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import Logo from '../../images/dfxFileLogo.png';
import styles from "./loginPage.module.css";
import { useHistory } from "react-router-dom";
import './loginCustom.css'


function RigisterForm() {
    const [firstName, setFirstName] = useState()
    const [lastName, setlastName] = useState()
    const [phNo, setPhNo] = useState()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [selectedGenderValue, setSelectedGenderValue] = useState('');
    const [conformPassword, setConformPassword] = useState()


    const [state, dispatch] = useReducer();
    const history = useHistory();
    const dialogInstanceRef = useRef(null);
    const formObjectRef = useRef(null);

    const floatFocus = (args) => {
        args.target.parentElement.classList.add('e-input-focus');
    };

    const floatBlur = (args) => {
        args.target.parentElement.classList.remove('e-input-focus');
    };

    const handleRadioChange = (event) => {
        setSelectedGenderValue(event.target.checked ? event.target.value : '');
    };


    useEffect(() => {
        const options = {
            rules: {
                'email': {
                    required: [true, '* Enter your email']
                },
                'firstName': {
                    required: [true, '* Enter your first name']
                },
                'lastName': {
                    required: [true, '* Enter your last name']
                },
                'phNo': {
                    required: [true, '* Enter your phone number'],
                    regex: [/^\d{10}$/, 'Your phone number must be 10 numbers']
                },
                'gender': {
                    required: [true, 'Please select a gender']
                },


                'password': {
                    required: [true, '* Enter your password'],
                    minLength: [8, '* Your password must be at least 8 characters long.'],
                    regex: [/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?-])/, '* Your password must contain at least one letter, one number, and one special character.']


                },
                'conformPassword': {
                    required: [true, '* Enter your confirm password'],
                    compare: ['#password', '* Passwords do not match']
                },
            }
        };

        formObjectRef.current = new FormValidator('#form1', options);

    }, []);

    const onSubmitClick = () => {
        if (formObjectRef.current.validate()) {
            history.push("/verifyNumber");
            formObjectRef.current.element.reset();

        } else {

        }

    };



    return (
        <React.Fragment>
            <div className="container-fluid  p-0">
                <div className="row m-0">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0">
                        <div className="position-relative">
                            <div className={styles.login_image} >
                                <div className={styles.imgDFXlogo}>
                                    <img src={Logo} style={{ width: '100px' }}></img>
                                    <div className={styles.logInName}>Giving Your <spn style={{ color: '#2F9FD9' }}> Data </spn> the care and Security it needs.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 logPagehightOverFlow">
                        <div className="logPagehightOverFlow">
                            <div className={styles.registerFormTopSet} >
                                <div className="row">
                                    <div className="col-12" >
                                        <div>
                                            <div className={styles.logName}>Registration</div>
                                            <form id="form1" method="post">
                                                <div className="form-group">
                                                    <label className={styles.inputlableNames}>First Name</label>
                                                    <TextBoxComponent type="text" name="firstName" value={firstName} onFocus={floatFocus} onBlur={floatBlur} placeholder="Enter your First Name" autocomplete="off" style={{ height: '50px' }} data-msg-containerid="firstNameError" />
                                                    <div className="mt-1" id="firstNameError" />

                                                </div>
                                                <div className="form-group">
                                                    <label className={styles.inputlableNames}>Last Name</label>
                                                    <TextBoxComponent type="text" name="lastName" value={lastName} onFocus={floatFocus} onBlur={floatBlur} placeholder="Enter your Last Name" autocomplete="off" style={{ height: '50px' }} data-msg-containerid="lastNameError" />
                                                    <div className="mt-1" id="lastNameError" />

                                                </div>
                                                <div className="form-group">
                                                    <label className={styles.inputlableNames}>Phone Number</label>
                                                    <TextBoxComponent type="text" name="phNo" value={phNo} onFocus={floatFocus} onBlur={floatBlur} placeholder="Enter your Phone Number" autocomplete="off" style={{ height: '50px' }} data-msg-containerid="phNoError" />
                                                    <div className="mt-1" id="phNoError" />

                                                </div>
                                                <div className="form-group">
                                                    <label className={styles.inputlableNames}>Email</label>
                                                    <TextBoxComponent type="email" name="email" value={email} onFocus={floatFocus} onBlur={floatBlur} placeholder="Enter your Email" autocomplete="off" style={{ height: '50px' }} data-msg-containerid="emailError" />
                                                    <div className="mt-1" id="emailError" />

                                                </div>

                                                <div className="form-group">
                                                    <label className={styles.inputlableNames}>Password</label>
                                                    <TextBoxComponent name="password" type="password" id="password" value={password} onFocus={floatFocus} onBlur={floatBlur} placeholder="Enter your Password" autocomplete="off" style={{ height: '50px' }} data-msg-containerid="passwordError" />

                                                    <div className="mt-1" id="passwordError" />


                                                </div>
                                                <div className="form-group">
                                                    <label className={styles.inputlableNames}>Confirm Password</label>
                                                    <TextBoxComponent name="conformPassword" type="password" value={conformPassword} onFocus={floatFocus} onBlur={floatBlur} placeholder="Enter your Confirm Password" autocomplete="off" style={{ height: '50px' }} data-msg-containerid="passwordConformError" />

                                                    <div className="mt-1" id="passwordConformError" />


                                                </div>

                                                <div className="form-group">
                                                    <label className={styles.inputlableNames}>Gender</label>
                                                    <div className="radiobtn">
                                                        <RadioButtonComponent label="Male" value="male" checked={selectedGenderValue === 'male'}
                                                            onChange={handleRadioChange} name="gender" data-msg-containerid="genderError" />
                                                        <RadioButtonComponent label="Female" data-msg-containerid="genderError" value="female" checked={selectedGenderValue === 'female'}
                                                            onChange={handleRadioChange} name="gender" />
                                                        <div className="mt-1" id="genderError" />


                                                    </div>

                                                </div>
                                                <div className="mt-5">

                                                    {/* <Link className="" to="verifyNumber"> */}
                                                    <ButtonComponent className={styles.logbtn} cssClass='e-info' onClick={onSubmitClick} > Registration </ButtonComponent>
                                                    {/* </Link> */}
                                                </div>
                                            </form>
                                            <div class="mb-4 d-flex justify-content-center align-items-center position-relative">

                                                <div class="position-absolute  px-3" style={{ "color": "#828282" }}>Already have an account? <spn > <Link className="" to="/" style={{ color: '#2F9FD9', textDecoration: 'none' }}>Log In</Link> </spn> </div>
                                            </div>

                                        </div>





                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default RigisterForm;