import * as React from 'react';
import styles from "./sidebar.module.css";

import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { NavLink } from "react-router-dom";
import { IoApps } from "react-icons/io5"






function Sidebar() {
    let sidebarobj;
    let width = '100px';
    function toggleClick() {
        sidebarobj.toggle();
    }
    function onCreate() {
        sidebarobj.element.style.visibility = '';
    }
    return (
        // Sidebar Element Declaration
        <div className="control-section">
            <SidebarComponent id="defaultSidebar" target=".main" ref={Sidebar => sidebarobj = Sidebar} style={{ visibility: "hidden", top: '57px', backgroundColor: '#2F9FD9' }} width={width} created={onCreate}>
                <div className="flex justify-between items-center sidebarMenuActive p-2">
                    <NavLink activeClassName="active" to="/dashboard" className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-white text-slate-900">
                        <div className={styles.sidenames}>
                            {/* <span className="e-icons e-large e-copy text-white" style={{ textAlign: 'center', fontSize: '36px' }}></span> */}
                            <IoApps style={{ textAlign: 'center', fontSize: '30px', margin: "auto" }} />
                            <span className={styles.sidebarName}>Dashboard</span>
                        </div>
                    </NavLink>
                </div>

                <div className="flex justify-between items-center sidebarMenuActive p-2">
                    <NavLink activeClassName='active' to="/inbox" className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-white text-slate-900"                    >
                        <div className={styles.sidenames}>
                            <span className="e-icons e-large e-copy" style={{ textAlign: 'center', fontSize: '36px' }}></span>
                            <span className={styles.sidebarName}>Inbox</span>
                        </div>
                    </NavLink>
                </div>

            </SidebarComponent>

        </div>);
}
export default Sidebar;