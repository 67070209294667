import './App.css';
import React, { useState, useEffect, Fragment } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
// import NavBar from "./components/NavBar";
import NavBar from "./components/NavBar";
import Sidebar from "./components/SideBar";
import LogIn from "./components/LogIn/Login"
import ForGotPassword from './components/LogIn/ForGetPassword';
import VerifiyNumber from './components/LogIn/VerificationNumber'
import ResetPassword from './components/LogIn/ResetPassword';
import Dashboard from './components/Dashboard/Dashboard';
import Inbox from "./components/Inbox/Inbox";

import RigisterForm from './components/LogIn/RigisterForm';



import Grid from "./components/Grid/Grid";

import Chart from "./components/Chart/ChartDoucumentsType";

import Schedule from "./components/Schedule/Schedule";

function App() {
  const [urlName, setUrlName] = useState('/')

  // useEffect(() => {
  //   // console.log(JSON.parse(isUserLoggedIn()).isEmployee)
  //   setUrlName('')
  //   let urlNamePath = ''
  //   const path = window.location.pathname.split('/')
  //   if (path.length > 0) {
  //     urlNamePath = path[1]
  //   }
  //   
  // }, [window.location.pathname])
  // console.log(urlName, window.location.pathname)
  const location = useLocation();

  useEffect(() => {
    // console.log('Location changed', location.pathname);
    setUrlName(location.pathname)
  }, [location]);
  // console.log(urlName, urlName === '/' || urlName === '/login' || urlName === '/verifyNumber' || urlName === '/forgotPassword')
  return (
    <React.Fragment>
      {/* <NavBar /> */}
      {urlName === '/' || urlName === '/login' || urlName === '/verifyNumber' || urlName === '/forgotPassword' || urlName === '/rigister' ?
        <Switch>
          <Route exact path="/" component={LogIn} />
          <Route exact path="/forgotPassword" component={ForGotPassword} />
          <Route exact path="/resetPassword" component={ResetPassword} />
          <Route exact path="/verifyNumber" component={VerifiyNumber} />
          <Route exact path='/rigister' component={RigisterForm} />
          <Route exact path="/Grid" component={Grid} />
          <Route path="/Chart" component={Chart} />
          <Route path="/Schedule" component={Schedule} />
        </Switch> :
        <React.Fragment>
          <NavBar />
          <Sidebar />
          <Switch>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/inbox" component={Inbox} />
          </Switch>
        </React.Fragment>
      }
      {/* <Footer /> */}
    </React.Fragment>
  );
}

export default App;
