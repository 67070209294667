import * as React from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel, ColumnSeries, Axis } from '@syncfusion/ej2-react-charts';
import * as ReactDOM from "react-dom";
import styles from "./chart.module.css";


import { useState, useEffect, useRef, useReducer } from 'react';

const data = [
    { country: "60", gold: 50, silver: 70, bronze: 45 },
    { country: "120", gold: 40, silver: 60, bronze: 55 },
    { country: "240", gold: 70, silver: 60, bronze: 50 },
    { country: "480", gold: 60, silver: 56, bronze: 40 },
    { country: "960", gold: 50, silver: 45, bronze: 35 },

];


function ChartDocumentAccess() {



    const primaryxAxis = {
        valueType: 'Category',
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        minorTickLines: { width: 0 },
        lineStyle: { width: 0 },


    };
    const primaryyAxis = { minimum: 0, maximum: 100, interval: 0 };
    const palette = ["#2F9FD9", "#115DFC", "#1F1AA8",];

    // const cornerradius = { bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 };
    return <React.Fragment>
        <div className="row justify-content-start mt-1 mx-3 p-0">
            <h3  className="chartTitle"> Document vs Access Time</h3>
        </div>

        <div className="d-flex my-5">
            <div className={styles.dashboardAccessGraph}><span className="ml-1" style={{ borderLeft: '5px solid #2F9FD9', display: 'grid', }}> &nbsp;Document With most chat history </span> <span className="text-center"> <h4>24</h4></span> </div>
            <div className={styles.dashboardAccessGraph} ><span style={{ borderLeft: '5px solid #115DFC', display: 'grid', }}>&nbsp; Document viewed long </span><span className="text-center"> <h4>5</h4></span> </div>
            <div className={styles.dashboardAccessGraph} > <span style={{ borderLeft: '5px solid #1F1AA8', display: 'grid', }}>&nbsp;Document Keep open long </span><span className="text-center"> <h4>20</h4></span> </div>
        </div>


        <ChartComponent id='acess' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} palette={palette} chartArea={{ border: { width: 0 } }}
            tooltip={{ enable: true }}
            legendSettings={{ background: "white", marginTop: "10%" }}>
            <Inject services={[ColumnSeries, Tooltip, DataLabel, Category]} />


            <SeriesCollectionDirective>

                <SeriesDirective dataSource={data} xName='country' yName='gold' name='Document With most chat history' legendShape='Circle' type='Column' fill="#2F9FD9">
                </SeriesDirective>
                <SeriesDirective dataSource={data} xName='country' yName='silver' type='Column' name='Document viewed long' legendShape='Rectangle' fill="#115DFC">
                </SeriesDirective>
                <SeriesDirective dataSource={data} xName='country' yName='bronze' name='Document Keep open long' legendShape='Rectangle' type='Column' fill="#1F1AA8">
                </SeriesDirective>
            </SeriesCollectionDirective>

        </ChartComponent>
        <div className="row justify-content-center mt-1 mx-3 p-0">
            <h6>Documents </h6>
        </div>
    </React.Fragment>
}
;
export default ChartDocumentAccess;