import * as React from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel, ColumnSeries } from '@syncfusion/ej2-react-charts';
import * as ReactDOM from "react-dom";

import { useState, useEffect, useRef, useReducer } from 'react';

const data = [
  { year: '2016', sales: 200 },
  { year: '2017', sales: 346 },
  { year: '2018', sales: 470 },
  { year: '2019', sales: 250 },
  { year: '2020', sales: 300 },
  { year: '2021', sales: 200 },
  { year: '2022', sales: 370 },
  { year: '2023', sales: 120 }

];


function ChartDocumentType() {



  const primaryxAxis = {
    valueType: 'Category', visible: false, title: 'Contactes'

  };
  const primaryyAxis = { minimum: 0, maximum: 500, interval: 0 };
  const palette = "#2F9FD9";


  // const cornerradius = { bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 };
  return <React.Fragment>
    <div className="row justify-content-start mt-1 mx-3 p-0">
      <h3 className="chartTitle">Document vs Type</h3>
    </div>


    <ChartComponent id='type' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} chartArea={{ border: { width: 0 } }}
      tooltip={{ enable: true }}
      legendSettings={{ background: "white", marginTop: "10%" }} >
      <Inject services={[ColumnSeries, Tooltip, DataLabel, Category]} />

      <SeriesCollectionDirective>
        <SeriesDirective dataSource={data} xName='year' yName='sales' type='Column' fill="#2F9FD9" width={100} name='Type' legendShape='Circle'>
        </SeriesDirective>
      </SeriesCollectionDirective>

    </ChartComponent>
    <div className="row justify-content-center mt-1 mx-3 p-0">
      <h6> Type </h6>
    </div>
  </React.Fragment>
}
;
export default ChartDocumentType;