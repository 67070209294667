import React, { useState, useEffect, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./navbar.module.css";
import { RiNotification3Line } from "react-icons/ri";
import { AiOutlineUser } from "react-icons/ai";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
import "./navbarCustom.css"




import Logo from '../../images/dfxFileLogo.png';


const menuItems = [
  { text: 'Option 1', id: 'option1' },
  { text: 'Option 2', id: 'option2' },
  { text: 'Option 3', id: 'option3' },
];
//TODO Web Template Studio: Add a new link in the NavBar for your page here.
// A skip link is included as an accessibility best practice. For more information visit https://www.w3.org/WAI/WCAG21/Techniques/general/G1.
const NavBar = () => {
  const [urlName, setUrlName] = useState('/')
  const location = useLocation();
  const [popupOpen, setPopupOpen] = React.useState(false);




  useEffect(() => {
    let l = location.pathname.slice(1);
    let name = l.charAt(0).toUpperCase() + l.slice(1);
    console.log(name)
    setUrlName(name)
  }, [location]);


  const handleFilterClick = () => { setPopupOpen(true); };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (popupOpen && e.target instanceof Node && !e.target.closest('.popup')) {
        setPopupOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [popupOpen]);


  return (
    <React.Fragment>
      <div className={styles.skipLink}>
        <a href="#mainContent">Skip to Main Content</a>
      </div>
      <nav className="navbar navbar-expand-sm navbar-light border-bottom justify-content-between" style={{ boxShadow: '10px 10px 10px #00000029', position: 'fixed', top: '0', width: '100%', zIndex: '10' }}>
        <img src={Logo} style={{ width: '40px', marginLeft: '18px' }}></img>
        <span className="pathNameDynamic">
          {urlName == 'Inbox' ? 'Documents - Inbox' : urlName}
          {/* {urlName} */}
        </span>
        <div className={styles.navbarnav}>
          <TooltipComponent content="Notifications"  >

            {/* <Link className="nav-item nav-link active" to=""> */}
            <div>
              <span className={styles.notifystyle}>

                <RiNotification3Line style={{ fontSize: '20px' }}>


                </RiNotification3Line>


              </span>
            </div>
            {/* <span className="e-badge e-badge-danger e-badge-overlap e-badge-notification">10</span> */}




            {/* </Link> */}
          </TooltipComponent>
          <TooltipComponent content="Proficle"  >

            {/* <Link className="nav-item nav-link  active" to=""> */}
            <div className="px-3">

              <span className={styles.notifystyle} >

                <AiOutlineUser style={{ fontSize: '20px' }} onClick={handleFilterClick} >


                </AiOutlineUser>



              </span>
            </div>
            {/* </Link> */}
          </TooltipComponent>

          {popupOpen && (
            <div className="popup  mt-1" style={{
              position: 'absolute', zIndex: '10', top: '45px', right: '42px', backgroundColor: 'white',
              border: '1px solid #A2A0A0',
              borderRadius: '6px'
            }} >
              <div className="usermenu">
                <Link className="nav-item nav-link  active" to="">

                  Log Out
                </Link>

              </div>
              <div className="usermenu"> <span style={{ marginTop: '-12px' }}>
                <Link className="nav-item nav-link  active">
                  User Proficle
                </Link>
              </span>
              </div>
            </div>
          )}





        </div>
      </nav>
    </React.Fragment>
  );
}
export default NavBar;
