import * as React from 'react';
import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor';
// import { TitleBar } from './title-bar';
import './default.component.css';
DocumentEditorContainerComponent.Inject(Toolbar);
// tslint:disable:max-line-length
function Default() {
     return (<div className='control-pane'>
        <div className='control-section'>
            <div id="documenteditor_container_body">
                <DocumentEditorContainerComponent id="container"  style={{ 'display': 'block' }} height={'590px'} enableToolbar={true} locale='en-US'/>
            </div>
        </div>
        
    </div>);
}
export default Default;