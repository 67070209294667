import React, { Children, Fragment } from "react";
import { useState, useEffect, useRef, useReducer } from 'react';
// import NavBar from "../NavBar";
// import Sidebar from "../SideBar";
import { activeData, expireDate } from "./data";
import styles from "./inbox.module.css";
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { TabComponent, TabItemDirective, TabItemsDirective, ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
import InboxBodyContent from "./InboxBodyContent";
import InboxBodyEmptyContent from "./InboxBodyEmptyContent";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { CiUndo } from "react-icons/ci"
import { CiLock } from "react-icons/ci"
import Word from '../../images/word.png';
import PDF from '../../images/pdf.png';
import { AiFillLock } from "react-icons/ai"
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { RxCaretRight } from "react-icons/rx";
import { RxCaretLeft } from "react-icons/rx";
import { CiFilter } from "react-icons/ci";
import './inboxCustom.css'
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
function Inbox() {
  const [inboxBodyData, setInboxBodyData] = useState();
  const [isOpen, setIsOpen] = useState(true);
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [filterSelectedValue, setFilterSelectedValue] = useState('All');
  const [filterActiveData, setFilterActiveData] = useState([]);
  const [filterExpireData, setFilterExpireData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let sidebarObject;
  let listObj;
  const handleFilterClick = () => { setPopupOpen(true); };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (popupOpen && e.target instanceof Node && !e.target.closest('.popup')) {
        setPopupOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [popupOpen]);


  function fetchExpiryAllFilterData(filData) {
    const arrayData = [];
    const today = new Date();
    const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const yesterdayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
    const thisWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
    const lastWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 7);
    const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const thisYearStart = new Date(today.getFullYear(), 0, 1);
    const lastYearStart = new Date(today.getFullYear() - 1, 0, 1);

    let filSortData = filData.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });

    const groupedRecords = filSortData.reduce((acc, record) => {
      const recordDate = new Date(record.expireDate);
      if (recordDate >= todayDate) {
        acc['Today'].push(record);
      } else if (recordDate >= yesterdayDate) {
        acc['Yesterday'].push(record);
      } else if (recordDate >= thisWeekStart) {
        acc['This Week'].push(record);
      } else if (recordDate >= lastWeekStart) {
        acc['Last Week'].push(record);
      } else if (recordDate >= thisMonthStart) {
        acc['This Month'].push(record);
      } else if (recordDate >= lastMonthStart) {
        acc['Last Month'].push(record);
      } else if (recordDate >= thisYearStart) {
        acc['This Year'].push(record);
      } else if (recordDate >= lastYearStart) {
        acc['Last Year'].push(record);
      } else {
        const year = recordDate.getFullYear();
        if (acc[year]) {
          acc[year].push(record);
        } else {
          acc[year] = [record];
        }
      }

      return acc;
    }, { 'Today': [], 'Yesterday': [], 'This Week': [], 'Last Week': [], 'This Month': [], 'Last Month': [], 'This Year': [], 'Last Year': [] });
    // console.log(groupedRecords, 'groupedRecords')

    for (const key in groupedRecords) {
      if (key === 'Today') {
        arrayData.push({ name: key, index: 8, children: groupedRecords[key] })
      } else if (key === 'Yesterday') {
        arrayData.push({ name: key, index: 7, children: groupedRecords[key] })
      } else if (key === 'This Week') {
        arrayData.push({ name: key, index: 6, children: groupedRecords[key] })
      } else if (key === 'Last Week') {
        arrayData.push({ name: key, index: 5, children: groupedRecords[key] })
      } else if (key === 'This Month') {
        arrayData.push({ name: key, index: 4, children: groupedRecords[key] })
      } else if (key === 'Last Month') {
        arrayData.push({ name: key, index: 3, children: groupedRecords[key] })
      } else if (key === 'This Year') {
        arrayData.push({ name: key, index: 2, children: groupedRecords[key] })
      } else if (key === 'Last Year') {
        arrayData.push({ name: key, index: 1, children: groupedRecords[key] })
      } else {
        arrayData.push({ name: key, index: 0, children: groupedRecords[key] })
      }
    }

    arrayData.sort(function (a, b) {
      if (a.index < b.index) {
        return 1;
      }
      if (a.index > b.index) {
        return -1;
      }
      if (a.name < b.name) {
        return 1;
      }
      if (a.name > b.name) {
        return -1;
      }
    });
    return arrayData;
  }




  function fetchAllFilterData(filData) {
    const arrayData = [];
    const today = new Date();
    const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const yesterdayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
    const thisWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
    const lastWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 7);
    const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const thisYearStart = new Date(today.getFullYear(), 0, 1);
    const lastYearStart = new Date(today.getFullYear() - 1, 0, 1);

    let filSortData = filData.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });

    const groupedRecords = filSortData.reduce((acc, record) => {
      const recordDate = new Date(record.date);
      if (recordDate >= todayDate) {
        acc['Today'].push(record);
      } else if (recordDate >= yesterdayDate) {
        acc['Yesterday'].push(record);
      } else if (recordDate >= thisWeekStart) {
        acc['This Week'].push(record);
      } else if (recordDate >= lastWeekStart) {
        acc['Last Week'].push(record);
      } else if (recordDate >= thisMonthStart) {
        acc['This Month'].push(record);
      } else if (recordDate >= lastMonthStart) {
        acc['Last Month'].push(record);
      } else if (recordDate >= thisYearStart) {
        acc['This Year'].push(record);
      } else if (recordDate >= lastYearStart) {
        acc['Last Year'].push(record);
      } else {
        const year = recordDate.getFullYear();
        if (acc[year]) {
          acc[year].push(record);
        } else {
          acc[year] = [record];
        }
      }

      return acc;
    }, { 'Today': [], 'Yesterday': [], 'This Week': [], 'Last Week': [], 'This Month': [], 'Last Month': [], 'This Year': [], 'Last Year': [] });
    // console.log(groupedRecords, 'groupedRecords')

    for (const key in groupedRecords) {
      if (key === 'Today') {
        arrayData.push({ name: key, index: 8, children: groupedRecords[key] })
      } else if (key === 'Yesterday') {
        arrayData.push({ name: key, index: 7, children: groupedRecords[key] })
      } else if (key === 'This Week') {
        arrayData.push({ name: key, index: 6, children: groupedRecords[key] })
      } else if (key === 'Last Week') {
        arrayData.push({ name: key, index: 5, children: groupedRecords[key] })
      } else if (key === 'This Month') {
        arrayData.push({ name: key, index: 4, children: groupedRecords[key] })
      } else if (key === 'Last Month') {
        arrayData.push({ name: key, index: 3, children: groupedRecords[key] })
      } else if (key === 'This Year') {
        arrayData.push({ name: key, index: 2, children: groupedRecords[key] })
      } else if (key === 'Last Year') {
        arrayData.push({ name: key, index: 1, children: groupedRecords[key] })
      } else {
        arrayData.push({ name: key, index: 0, children: groupedRecords[key] })
      }
    }

    arrayData.sort(function (a, b) {
      if (a.index < b.index) {
        return 1;
      }
      if (a.index > b.index) {
        return -1;
      }
      if (a.name < b.name) {
        return 1;
      }
      if (a.name > b.name) {
        return -1;
      }
    });
    return arrayData;
  }
  function fetchExpireFilterData(filData) {
    const arrayData = [];
    const today = new Date();
    const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const tomdayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)

    const thisWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() + today.getDay());
    const lastWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 7);
    const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const thisYearStart = new Date(today.getFullYear(), 0, 1);
    const lastYearStart = new Date(today.getFullYear() + 1, 0, 1);

    let filSortData = filData.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });

    const groupedRecords = filSortData.reduce((acc, record) => {
      const recordDate = new Date(record.expireDate);
      // console.log(recordDate, todayDate, recordDate >= todayDate)
      if (recordDate <= todayDate) {
        acc['Today'].push(record);
      } else if (recordDate <= tomdayDate) {
        acc['Tomorrow'].push(record);
      } else if (recordDate <= thisWeekStart) {
        acc['This Week'].push(record);
      } else if (recordDate <= lastWeekStart) {
        acc['Next Week'].push(record);
      } else if (recordDate <= thisMonthStart) {
        acc['This Month'].push(record);
      } else if (recordDate <= lastMonthStart) {
        acc['Next Month'].push(record);
      } else if (recordDate <= thisYearStart) {
        acc['This Year'].push(record);
      } else if (recordDate <= lastYearStart) {
        acc['Next Year'].push(record);
      } else {
        const year = recordDate.getFullYear();
        if (acc[year]) {
          acc[year].push(record);
        } else {
          acc[year] = [record];
        }
      }

      return acc;
    }, { 'Today': [], 'Tomorrow': [], 'This Week': [], 'Next Week': [], 'This Month': [], 'Next Month': [], 'This Year': [], 'Next Year': [] });
    // console.log(groupedRecords, 'groupedRecords')

    for (const key in groupedRecords) {
      if (key === 'Today') {
        arrayData.push({ name: key, index: 8, children: groupedRecords[key] })
      } else if (key === 'Tomorrow') {
        arrayData.push({ name: key, index: 7, children: groupedRecords[key] })
      } else if (key === 'This Week') {
        arrayData.push({ name: key, index: 6, children: groupedRecords[key] })
      } else if (key === 'Next Week') {
        arrayData.push({ name: key, index: 5, children: groupedRecords[key] })
      } else if (key === 'This Month') {
        arrayData.push({ name: key, index: 4, children: groupedRecords[key] })
      } else if (key === 'Next Month') {
        arrayData.push({ name: key, index: 3, children: groupedRecords[key] })
      } else if (key === 'This Year') {
        arrayData.push({ name: key, index: 2, children: groupedRecords[key] })
      } else if (key === 'Next Year') {
        arrayData.push({ name: key, index: 1, children: groupedRecords[key] })
      } else {
        arrayData.push({ name: key, index: 0, children: groupedRecords[key] })
      }
    }
    // const orderedKeys = ['Last Year', 'This Year', 'Last Month', 'This Month', 'Last Week', 'This Week', 'Today'];
    // console.log(arrayData)
    // const orderedKeys = ['Today', 'This Week', 'Last Week', 'This Month', 'Last Month', 'This Year', 'Last Year'];
    arrayData.sort(function (a, b) {
      if (a.index < b.index) {
        return 1;
      }
      if (a.index > b.index) {
        return -1;
      }
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
    });
    return arrayData
  }
  function fetchDocTypeFilterData(filData) {
    const arrayData = [];
    const groupedRecords = filData.reduce((acc, item) => {
      const name = item.sub;
      if (!acc[name]) {
        acc[name] = [];
      }
      acc[name].push(item);
      return acc;
    }, {});
    for (const key in groupedRecords) {
      arrayData.push({ name: key, children: groupedRecords[key] })
    }
    arrayData.sort((a, b) => b.children.length - a.children.length);
    return arrayData;
  }
  function fetchNameFilterData(filData) {
    const arrayData = [];
    const groupedRecords = filData.reduce((acc, item) => {
      const name = item.fromMailName;
      if (!acc[name]) {
        acc[name] = [];
      }
      acc[name].push(item);
      return acc;
    }, {});
    for (const key in groupedRecords) {
      arrayData.push({ name: key, children: groupedRecords[key] })
    }
    arrayData.sort((a, b) => b.children.length - a.children.length);
    return arrayData;
  }
  useEffect(() => {
    let actFilteryData = []
    let expireFilteryData = []
    if (filterSelectedValue === 'All') {
      actFilteryData = fetchAllFilterData(activeData)
      expireFilteryData = fetchAllFilterData(expireDate)

    } else if (filterSelectedValue === 'Expiry') {
      actFilteryData = fetchExpireFilterData(activeData)
      expireFilteryData = fetchExpiryAllFilterData(expireDate)

    } else if (filterSelectedValue === 'Doc Type') {
      actFilteryData = fetchDocTypeFilterData(activeData)
      expireFilteryData = fetchDocTypeFilterData(expireDate)
    }
    else if (filterSelectedValue === 'Sender') {
      actFilteryData = fetchNameFilterData(activeData)
      expireFilteryData = fetchNameFilterData(expireDate)
    }
    setFilterActiveData(actFilteryData)
    setFilterExpireData(expireFilteryData)
    setIsLoading(false)
  }, [filterSelectedValue]);

  let fields = { id: 'id', text: 'text' };

  const filterData = ['All', 'Expiry', 'Doc Type', 'Sender',];


  let headerText = [{ text: "Active" }, { text: "Expired" }];
  function onListSelect(args) {
    setInboxBodyData(args)
    // if (window.innerWidth < 980) {
    //   setIsOpen(!isOpen)
    // }
    // args.item.classList.remove('e-active');
  }
  var activeCardData
  function listTemplate(inboxData) {
    return (<Fragment>
      {inboxData.children.length > 0 && <div className={styles.dateFilter}>{inboxData.name}</div>}
      {inboxData.children && inboxData.children.map((data, index) => {
        activeCardData = inboxBodyData ? inboxBodyData : '';
        let currentTagActive = data.id === activeCardData.id;
        return (
          <div className="containr-fluid" onClick={() => onListSelect(data)}>
            <div className="row">
              <div className="col-12">
                <div class="e-card" className={currentTagActive ? styles.inboxCardActive : styles.inboxCard} id="basic">
                  <div className="e-card-content">                   
                      <div className="row main_e_card_content">
                        <div className="col-2 ">
                          {data.fileType === 'Word' &&
                            <img src={Word} style={{ width: "60px", marginLeft: '-8px' }}></img>

                          }
                          {data.fileType == 'PDF' &&
                            <img src={PDF} style={{ width: "45px" }}></img>
                          }
                        </div>
                        <div className="col-10">
                          <div className="row">
                            <div className="col-7 p-0">
                              <div className="sender_lable">{data.fromMailName}</div>
                            </div>
                            <div className="col-5 p-0   ">
                              <div className="text-right sender_date">{data.date}</div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 p-0">
                             <div className="sender_company"> {data.sub}</div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-7 p-0">
                            <div className="sender_company_date">{data.subContent}</div>
                            </div>
                            <div className="col-5 p-0 text-right">
                              {data.security=='yes' &&  <div className="sender_company_alert"><AiFillLock/></div> } 
                           
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    
                  </div>
                </div>
              </div>
              {/* <div className="col-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {data.security == 'yes' &&
                  <TooltipComponent content="Security"  >

                    <div className="mr-3">
                      <AiFillLock style={{ fontSize: '18px' }} />
                    </div>
                  </TooltipComponent>
                }
              </div> */}

            </div>
          </div>
        )
      })}
    </Fragment>
    );
  }
  // console.log(filterActiveData, 'filterActiveData')
  function content0() {

    return <div className="maincontent">
      <ListViewComponent
        id="listView"
        ref={(ListView) => (listObj = ListView)}
        template={listTemplate}
        cssClass="e-list-template"
        dataSource={filterActiveData}
      // fields={fields}
      // select={onListSelect.bind(this)}
      ></ListViewComponent>
    </div>;
  }

  function content1() {

    return <div className="maincontent">
      <ListViewComponent
        id="listView"
        ref={(ListView) => (listObj = ListView)}
        template={listTemplate}
        cssClass="e-list-template"
        dataSource={filterExpireData}
      // fields={fields}
      // select={onListSelect.bind(this)}
      ></ListViewComponent>
    </div>;
  }

  function toggleClick() {
    sidebarObject.toggle();
  }
  // Close the Sidebar
  function closeClick() {
    sidebarObject.hide();
  }
  const toggleSidebar = () => {
    setIsOpen(!isOpen);

  };

  const handleOpen = () => {
    console.log("Sidebar opened");
  };

  const handleClose = () => {
    console.log("Sidebar closed");
  };

  // console.log(activeCardData, "activeCardData")

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth > 980);
    };
    handleResize(); // call on first render
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onTabClick = () => {
    setFilterSelectedValue("All")
  };

  // console.log(isOpen, "open")


  return (
    <React.Fragment>
      {!isLoading &&
        <div className=''>
          <div className="control-section" id="sidebar-wrapper">
            {/* main content declaration */}
            {/* className={isOpen ? 'sidebar open' : 'sidebar closed'} */}




            <div className={isOpen ? styles.inbox_page : styles.inbox_pageChange}>
              {inboxBodyData ? <InboxBodyContent mailData={inboxBodyData ? inboxBodyData : activeData[0]} /> : <InboxBodyEmptyContent />}


            </div>
            {/* end of main content declaration */}
            {/* sidebar element declaration */}
            <SidebarComponent
              // id="default-sidebar"
              ref={(Sidebar) => (sidebarObject = Sidebar)}

              // className="default-sidebar"
              style={{ top: '57px', left: '101px', position: 'fixed', zIndex: '100', transition: 'all 300ms linear', overflow: 'hidden' }}
              // class='inboxSideBar'
              width="450px"
              zIndex='100'
              target=".maincontent"
              position="Left"
              // created={onCreate}
              type='over'
              isOpen={isOpen}
              e-open={handleOpen}
              e-close={handleClose}>
              <div>
                <div className="container-fluid">
                  <div className="row mt-3">
                    <div className="col-11">
                      <TextBoxComponent type="text" name="totalSearch" className={styles.inboxsubmit} placeholder="Search" autocomplete="off" style={{ height: '30px' }} />
                    </div>
                    <div className="col-1">
                      <CiUndo style={{ fontSize: '25px', marginLeft: '-13px' }} />

                    </div>
                    {/* 
                    <div className="col-2">
                      <div class="">
                        <div className="filter-icon" >
                          <TooltipComponent content="Filter"  >

                            <div onClick={handleFilterClick}>
                              <CiFilter style={{ fontSize: '25px', float: 'right', cursor: 'pointer' }} />
                            </div>
                          </TooltipComponent>
                          {popupOpen && (
                            <div className="popup px-3 mt-1" style={{
                              position: 'absolute', zIndex: '10', top: '30px', right: '25px', backgroundColor: 'white', boxShadow: '0px 3px 6px #00000029',
                              border: '1px solid #A2A0A0'
                            }} >
                              <div><h6>Read</h6></div>
                              <div> <h6>Unread</h6></div>
                            </div>
                          )}
                        </div>
                      </div>

                    </div> */}

                  </div>

                </div>
              </div>
              <div className="container-fluid ">
                <div className="filDrop">
                  <DropDownListComponent id="ddlelement"
                    dataSource={filterData}
                    cssClass="e-input"
                    style={{ textAlign: 'right' }}
                    value={filterSelectedValue}
                    change={(e) => setFilterSelectedValue(e.value)}
                    popupHeight="150px" popupWidth="105px" placeholder="" /></div>


                <div className="row">
                  <div className="col">

                    {/* <div id="target" onClick={handleFilterClick} class="inboxfilterMenu"><CiFilter style={{ fontSize: '25px' }} /></div> */}
                    {/* <ContextMenuComponent id='contextmenu' target='#target' items={menuItems} /> */}
                    <TabComponent heightAdjustMode='Auto' selecting={onTabClick} style={{ width: '85%' }}>
                      <TabItemsDirective >
                        <TabItemDirective header={headerText[0]} content={content0} />
                        <TabItemDirective header={headerText[1]} content={content1} />

                      </TabItemsDirective>
                    </TabComponent>

                  </div>

                </div>

              </div>
              {/* <button style={{ position: 'absolute', bottom: '10', zIndex: '1000' }} onClick={toggleSidebar}>Toggle Sidebar</button> */}
            </SidebarComponent>
            <div className={styles.sidebarLefticon}>
              <TooltipComponent content="Inbox Collapse"  >

                {isOpen == true ? <RxCaretLeft style={{ fontSize: '35px', cursor: 'pointer' }} onClick={toggleSidebar} /> : <RxCaretRight style={{ fontSize: '35px', cursor: 'pointer' }} onClick={toggleSidebar} />}
              </TooltipComponent>
            </div>
          </div>


        </div>
      }

    </React.Fragment>
  );
}

export default Inbox;

