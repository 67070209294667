import React from "react";
import { useState, useEffect, useRef, useReducer } from 'react';
import { Link } from "react-router-dom";
import { TextBoxComponent, FormValidator } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import Logo from '../../images/dfxFileLogo.png';
import GoogleLogo from '../../images/google.png';
import MicrosoftLogo from '../../images/microsoft.png';
import styles from "./loginPage.module.css";
import { useHistory } from "react-router-dom";
import './loginCustom.css'


function Login() {
    const [logEmail, setLogEmail] = useState('');
    const [logPassword, setLogPassWord] = useState('');
    const [state, dispatch] = useReducer();
    const history = useHistory();



    const dialogInstanceRef = useRef(null);
    const formObjectRef = useRef(null);
    const animationSettings = { effect: 'Zoom' };

    const floatFocus = (args) => {
        args.target.parentElement.classList.add('e-input-focus');
    };

    const floatBlur = (args) => {
        args.target.parentElement.classList.remove('e-input-focus');
    };

    const onOverlayClick = () => {
        dialogInstanceRef.current.hide();
    };

    useEffect(() => {
        const options = {
            rules: {
                'email': {
                    required: [true, '* Enter your email']
                },
                'password': {
                    required: [true, '* Enter your password'],
                    minLength: [8, '* Your password must be at least 8 characters long.'],
                    regex: [/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?-])/, '* Your password must contain at least one letter, one number, and one special character.']


                },
            }
        };

        formObjectRef.current = new FormValidator('#form1', options);
        // formObjectRef.addRules('password', { maxLength: [6, 'Password at least 6 characters'] });

    }, []);

    const onSubmitClick = () => {
        if (formObjectRef.current.validate()) {
            history.push("/verifyNumber");
            formObjectRef.current.element.reset();
            // dialogInstanceRef.current.show();

        } else {

        }

    };



    return (
        <React.Fragment>
            <div className="container-fluid  p-0">
                <div className="row m-0">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0">
                        <div className="position-relative">
                            <div className={styles.login_image} >
                                <div className={styles.imgDFXlogo}>
                                    <img src={Logo} style={{ width: '100px' }}></img>
                                    <div className={styles.logInName}>Giving Your <spn style={{ color: '#2F9FD9' }}> Data </spn> the care and Security it needs.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 logPagehightOverFlow">
                        <div className={styles.loginpageTopSet} >
                            <div className="row">
                                <div className="col-12" >
                                    <div>
                                        <div className={styles.logName}>LogIn</div>
                                        <form id="form1" method="post">
                                            <div className="form-group">
                                                <label className={styles.inputlableNames}>Email</label>
                                                <TextBoxComponent type="email" name="email" value={logEmail} onFocus={floatFocus} onBlur={floatBlur} placeholder="Enter your Email" autocomplete="off" style={{ height: '50px' }} data-msg-containerid="emailError" />
                                                <div className="mt-1" id="emailError" />

                                            </div>

                                            <div className="form-group">
                                                <label className={styles.inputlableNames}>Password</label>
                                                <TextBoxComponent name="password" type="password" value={logPassword} onFocus={floatFocus} onBlur={floatBlur} placeholder="Enter your Password" autocomplete="off" style={{ height: '50px' }} data-msg-containerid="passwordError" />

                                                <div className="mt-1" id="passwordError" />

                                                <div className="float-right">          <Link className="" to="forgotPassword" style={{ color: '#2F9FD9', textDecoration: 'none' }}>Forgot Password ?</Link></div>

                                            </div>
                                            <div className="mt-5">

                                                {/* <Link className="" to="verifyNumber"> */}
                                                <ButtonComponent className={styles.logbtn} cssClass='e-info' onClick={onSubmitClick} >   Log In</ButtonComponent>
                                                {/* </Link> */}
                                            </div>
                                        </form>
                                        <div class="mb-4 d-flex justify-content-center align-items-center position-relative">
                                            <hr class="w-100"></hr>
                                            <div class="position-absolute bg-white px-3" style={{ "color": "#828282" }}>or log in with</div>
                                        </div>
                                        <ButtonComponent className={styles.signGoogle} > <img src={GoogleLogo} style={{ width: "25px", marginRight: '10px' }}></img>
                                            Sign in with Google</ButtonComponent>
                                        <ButtonComponent className={styles.signGoogle} > <img src={MicrosoftLogo} style={{ width: "25px", marginRight: '10px' }}></img> Sign in with Microsoft</ButtonComponent>

                                    </div>

                                    <div class="mb-4 d-flex justify-content-center align-items-center position-relative">

                                        <div class="position-absolute  px-3" style={{ "color": "#828282" }}>Don't have an account? <spn > <Link className="" to="/rigister" style={{ color: '#2F9FD9', textDecoration: 'none' }}>Rigister</Link> </spn> </div>
                                    </div>



                                    {/* Don't have an account? */}




                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default Login;