import React from "react";
import { useState, useEffect, useRef, useReducer } from 'react';
import { Link } from "react-router-dom";
import NavBar from "../NavBar";
import Sidebar from "../SideBar";
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { HiArrowTrendingUp } from "react-icons/hi2"
import { HiArrowTrendingDown } from "react-icons/hi2"
import { AxisModel, Category, ChartComponent, ColumnSeries, Inject, SeriesCollectionDirective, SeriesDirective } from '@syncfusion/ej2-react-charts';
import Chart from "../Chart/ChartDoucumentsType"
import ColumnChart from "../Chart/ChartDocumentContact"
import DocumentAccess from "../Chart/ChartDocumentAccess"
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { activeData } from '../Inbox/data';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import DocumentExpiry from "../Chart/ChartDocumentExpiry"
// import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns'; Inject
import { MultiSelectComponent, DropDownListComponent, CheckBoxSelection } from '@syncfusion/ej2-react-dropdowns';

import './DashboardCustom.css'





import styles from "./dashboard.module.css";







function Dashboard() {
    const [senderData, setSenderData] = useState()
    const [selectedSenderData, setSelectedSenderData] = useState([]);
    const [documentData, setDocumentData] = useState([]);
    const [selectDocumentData, setSelectDocumentData] = useState([]);
    const [selectSubDocumentData, setSelectSubDocumentData] = useState([]);
    // const [selectedSubDocumentData, setselectedSubDocumentData] = useState([]);

    const durationData = [{ id: 'Today', value: 'Today' },
    { id: 'Tomorrow', value: 'Tomorrow' },
    { id: 'Yesterday', value: 'Yesterday' },
    { id: 'This Week', value: 'This Week' },
    { id: 'Next Week', value: 'Next Week' },
    { id: 'Last Week', value: ' Last Week' },
    { id: 'This Month', value: ' This Month' },
    { id: 'Next Month', value: 'Next Month' },
    { id: 'Last Month', value: 'Last Month' },
    { id: 'This Year', value: 'This Year' },
    { id: 'Next Year', value: 'Next Year' },
    { id: 'Last Year', value: 'Last Year' },
    ];

    const senderAllData = [
        { value: '1', text: 'Venkata Sainath Kadiyala' },
        { value: '2', text: 'Sushma Maganti' },
        { value: '3', text: 'Rajkumar Nagaboyina' },
        { value: '4', text: 'Anil P' },
        { value: '5', text: 'Anji R' },
        { value: '6', text: 'Venkatesh M' },
        { value: '7', text: 'Upendra P' },
        { value: '8', text: 'Madhu S' },
        { value: '9', text: 'Akhil A' },
        { value: '10', text: 'Raghu L' },
        { value: '11', text: 'Arun S' },
        { value: '12', text: ' Satya A' },

    ];
    const documentsAllData = [
        { value: '1', text: 'Company Incorporation Certificate' },
        { value: '2', text: 'Orgination important files details' },
        { value: '3', text: 'Company important files details' },
        { value: '4', text: 'House important files details' },
        { value: '5', text: 'Land Details' },
        { value: '6', text: 'Office files details' },
        { value: '7', text: 'Incorporation Certificate' },
        { value: '8', text: 'Office important files details' },
        { value: '9', text: 'Car important files details' },
        { value: '10', text: 'Bike important files details' },


    ];
    const documentsAubsAllData = [
        { value: '1', text: 'PAN' },
        { value: '2', text: 'Employee' },
        { value: '3', text: 'Tax' },
        { value: '4', text: 'Land' },
        { value: '5', text: 'Aggrement Documents' },
        { value: '6', text: 'Person' },
        { value: '7', text: 'Information' },
        { value: '8', text: 'Loan' },
        { value: '9', text: 'Accounts' },
        { value: '10', text: 'Attendance' },
        { value: '10', text: 'TDS' },
        { value: '11', text: 'Doc' },




    ]
    const fields = { text: 'value', value: 'id' };
    const checkFields = { text: 'value', value: 'Id' };


    const [state, dispatch] = useReducer();

    // useEffect(() => {
    //     const filterDocSubData = activeData.filter((item) => selectedSubDocumentData.some((x) => x == item.sub));
    //     const subDocMapData = filterDocSubData.map(Data => ({
    //         value: Data.id,
    //         text: Data.subContent,
    //     }))
    //     setselectedSubDocumentData(subDocMapData)

    // }, [selectedSubDocumentData]);
    useEffect(() => {
        const filterDocSubData = activeData.filter((item) => selectDocumentData.some((x) => x == item.sub));
        const subDocMapData = filterDocSubData.map(Data => ({
            value: Data.id,
            text: Data.subContent,
        }))
        setSelectSubDocumentData(subDocMapData)

    }, [selectDocumentData]);

    useEffect(() => {
        const filteredData = activeData.filter((item) => selectedSenderData.some((x) => x === item.fromMailName));
        const docMapData = filteredData.map(Data => ({
            value: Data.id,
            text: Data.sub,
        }))
        setDocumentData(docMapData)

    }, [selectedSenderData]);



    useEffect(() => {
        const uniqueData = activeData.filter((item, index, arr) => {
            return arr.findIndex((t) => t.fromMailName === item.fromMailName) === index;
        });
        const senderMapData = uniqueData.map(Data => ({
            value: Data.id,
            text: Data.fromMailName,

        }))
        setSenderData(senderMapData)

    }, []);

    useEffect(() => {
        const filteredData = activeData.filter((item) => selectedSenderData.some((x) => x === item.fromMailName));
        const docMapData = filteredData.map(Data => ({
            value: Data.id,
            text: Data.sub,
        }))
        setDocumentData(docMapData)

    }, [selectedSenderData]);




    // const handleSelectSubDocumet = (e) => {
    //     selectedSubDocumentData(e.value);
    // };

    const handleSelectSender = (e) => {
        setSelectedSenderData(e.value);
    };

    const handleSelectDocumet = (e) => {
        setSelectDocumentData(e.value);
    };

    return (
        <React.Fragment>
            <div className={styles.dashboardBody}>
                <div className="mx-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 my-3">
                                {/* <DateRangePickerComponent id="daterangepicker" placeholder='Select a range' /> */}
                                <DropDownListComponent cssClass="custom-dropdown"
                                    dataSource={durationData} fields={fields} placeholder="Select Date Duration" />
                            </div>
                            <div className="col-sm-12 col-md-3 my-3">
                                <MultiSelectComponent id="checkbox" change={handleSelectSender} value={selectedSenderData} dataSource={senderAllData} placeholder="Select Sender" mode="CheckBox" showSelectAll={true} showDropDownIcon={true} filterBarPlaceholder="Search Sender" popupHeight="350px">
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                            </div>
                            <div className="col-sm-12 col-md-3 my-3">
                                <MultiSelectComponent id="documentcheckbox" dataSource={documentsAllData} value={selectDocumentData} change={handleSelectDocumet} placeholder="Select Document Type" mode="CheckBox" showSelectAll={true} showDropDownIcon={true} filterBarPlaceholder="Search Document Type" popupHeight="350px">
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                            </div>
                            <div className="col-sm-12 col-md-3 my-3" >
                                <div className="row">
                                    <div className="col-9">
                                        {/* value={selectedSubDocumentData} change={handleSelectSubDocumet} */}
                                        <MultiSelectComponent id="documentsubcheckbox" dataSource={documentsAubsAllData} placeholder="Select Document Sub Type" mode="CheckBox" showSelectAll={true} showDropDownIcon={true} filterBarPlaceholder="Search Document Sub Type" popupHeight="350px">
                                            <Inject services={[CheckBoxSelection]} />
                                        </MultiSelectComponent>
                                        {/* <MultiSelectComponent placeholder="Select Document Sub Type" dataSource={selectSubDocumentData} /> */}
                                    </div>
                                    <div className="col-3">
                                        <ButtonComponent className="btn_slide_right_btn" cssClass='e-primary'>Filter</ButtonComponent>

                                    </div>
                                </div>

                                {/* <TextBoxComponent type="text" name="totalSearch" className={styles.nosubmit} value={totalSearch} placeholder="search" autocomplete="off" style={{ height: '30px' }} /> */}

                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div class="row" className={styles.dashboardCol}>
                            <div className="col-sm-12 col-md-4 col-lg-3 my-3">
                                <div class="e-card" className={styles.bashBoardCard} id="basic">

                                    <div className="e-card-content">
                                        <div className={styles.dashboardCardBodyName}>
                                            Today's Documents
                                        </div>
                                        <div className={styles.dashboardCardbodyNumber}>
                                            20
                                        </div>
                                        {/* <div className="d-flex">
                                            <HiArrowTrendingUp className="ml-4" style={{ color: 'green', fontSize: '28px' }} /> <span className="mx-2" style={{ color: 'green' }}>  +41% </span>  vs <span className="ml-2" style={{ color: '#3C3857' }}>yesterday</span>
                                        </div> */}


                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-3  my-3">
                                <div class="e-card" className={styles.bashBoardCard} id="basic">

                                    <div className="e-card-content">
                                        <div className={styles.dashboardCardBodyName}>
                                            This Week Documents
                                        </div>
                                        <div className={styles.dashboardCardbodyNumber}>
                                            12
                                        </div>
                                        {/* <div className="d-flex">
                                            <HiArrowTrendingDown className="ml-4" style={{ color: 'red', fontSize: '28px' }} /> <span className="mx-2" style={{ color: 'red' }}>  -20% </span>  vs <span className="ml-2" style={{ color: '#3C3857' }}>last week </span>
                                        </div> */}

                                    </div>
                                </div>

                            </div>
                            <div className=" col-sm-12 col-md-4 col-lg-3 my-3">
                                <div class="e-card" className={styles.bashBoardCard} id="basic">
                                    <div className="e-card-content">
                                        <div className={styles.dashboardCardBodyName}>
                                            Unread Documents
                                        </div>
                                        <div className={styles.dashboardCardbodyNumber}>
                                            14
                                        </div>
                                        {/* <div className="d-flex">
                                            <HiArrowTrendingDown className="ml-4" style={{ color: 'red', fontSize: '28px' }} /> <span className="mx-2" style={{ color: 'red' }}>  -10% </span>  vs <span className="ml-2" style={{ color: '#3C3857' }}> yesterday </span>
                                        </div> */}

                                    </div>
                                </div>

                            </div>
                            {/* <div className=" col-sm-12 col-md-4 col-lg-2  my-3">
                                <div class="e-card" className={styles.bashBoardCard} id="basic">

                                    <div className="e-card-content">
                                        <div className={styles.dashboardCardBodyName}>
                                            Expired
                                        </div>
                                        <div className={styles.dashboardCardbodyNumber}>
                                            18
                                        </div>
                                        <div className="d-flex">
                                            <HiArrowTrendingUp className="ml-4" style={{ color: 'green', fontSize: '28px' }} /> <span className="mx-2" style={{ color: 'green' }}>  +41% </span>  vs <span className="ml-2" style={{ color: '#3C3857' }}>yesterday</span>
                                        </div>

                                    </div>
                                </div>

                            </div> */}
                            <div className=" col-sm-12 col-md-4 col-lg-3  my-3">
                                <div class="e-card" className={styles.bashBoardCard} id="basic">

                                    <div className="e-card-content">
                                        <div className={styles.dashboardCardBodyName}>
                                            To be  Expired ( Week )
                                        </div>
                                        <div className={styles.dashboardCardbodyNumber}>
                                            24
                                        </div>

                                        {/* <div className="d-flex">
                                            <HiArrowTrendingUp className="ml-4" style={{ color: 'green', fontSize: '28px' }} /> <span className="mx-2" style={{ color: 'green' }}>  +41% </span>  vs <span className="ml-2" style={{ color: '#3C3857' }}>yesterday</span>
                                        </div> */}
                                    </div>
                                </div>

                            </div>
                            {/* <div className=" col-sm-12 col-md-4 col-lg-2  my-3">
                                <div class="e-card" className={styles.bashBoardCard} id="basic">

                                    <div className="e-card-content">
                                        <div className={styles.dashboardCardBodyName}>
                                            To be  Active
                                        </div>
                                        <div className={styles.dashboardCardbodyNumber}>
                                            14
                                        </div>

                                        <div className="d-flex">
                                            <HiArrowTrendingUp className="ml-4" style={{ color: 'green', fontSize: '28px' }} /> <span className="mx-2" style={{ color: 'green' }}>  +41% </span>  vs <span className="ml-2" style={{ color: '#3C3857' }}>yesterday</span>
                                        </div>
                                    </div>
                                </div>

                            </div> */}

                        </div>
                    </div>


                    <div className="container-fluid">
                        <div className='row colspan-2'>
                            <div className="col-sm-12 col-md-12 col-lg-6 my-3">
                                <div class="e-card" className={styles.bashBoardCard} id="basic">

                                    <div className="e-card-content">
                                        <div>
                                            <ColumnChart />


                                        </div>


                                    </div>
                                </div>

                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-6 my-3">
                                <div class="e-card" className={styles.bashBoardCard} id="basic">

                                    <div className="e-card-content">
                                        <Chart> </Chart>



                                    </div>
                                </div>

                            </div>

                            {/* <div className="col-sm-12 col-md-12 col-lg-2 my-3">
                                <div class="e-card" className={styles.bashBoardCard} id="basic">

                                    <div className="e-card-content" style={{ maxHeight: '206% !important' }}>



                                    </div>
                                </div>

                            </div> */}


                        </div>

                    </div>

                    <div className="container-fluid">
                        <div className='row colspan-2'>
                            <div className="col-sm-12 col-md-12 col-lg-6 my-3">
                                <div class="e-card" className={styles.bashBoardCard} id="basic">

                                    <div className="e-card-content">
                                        <div>
                                            <DocumentAccess></DocumentAccess>


                                        </div>


                                    </div>
                                </div>

                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-6 my-3">
                                <div class="e-card" className={styles.bashBoardCard} id="basic">

                                    <div className="e-card-content">
                                        <DocumentExpiry> </DocumentExpiry>



                                    </div>
                                </div>

                            </div>




                        </div>

                    </div>




                </div>

            </div>


        </React.Fragment>
    );
}

export default Dashboard;