import React from "react";
import { useState, useEffect, useRef, useReducer } from 'react';
import { Link } from "react-router-dom";


import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import Logo from '../../images/dfxFileLogo.png';

import styles from "./loginPage.module.css";




function ResetPassword() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [state, dispatch] = useReducer();



    return (
        <React.Fragment>
            <div className="container-fluid logPagehightOverFlow p-0">
                <div className="row m-0">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0">
                        <div className="position-relative">
                            <div className={styles.login_image} >
                                <div className={styles.imgDFXlogo}>
                                    <img src={Logo} style={{ width: '100px' }}></img>
                                    <div className={styles.logInName}>Giving Your <spn style={{ color: '#2F9FD9' }}> Data </spn> the care and Security it needs.</div>


                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">

                        <div className={styles.loginpageTopSet} >
                            <div className={styles.logName}>Reset Password</div>
                            <form id="form1" method="post">
                                <div className="form-group">
                                    <label className={styles.inputlableNames}>New Password</label>
                                    <TextBoxComponent type="password" name="newPassword" value={newPassword} placeholder="Create new Password" autocomplete="off" style={{ height: '50px' }} />
                                </div>
                                <div className="form-group">
                                    <label className={styles.inputlableNames}>Confirm Password</label>
                                    <TextBoxComponent type="password" name="confirmPassword" value={confirmPassword} placeholder="Confirm Password" autocomplete="off" style={{ height: '50px' }} />
                                </div>

                                <div className="mt-5">

                                    <ButtonComponent className={styles.logbtn} cssClass='e-info' > Continue </ButtonComponent>
                                </div>
                            </form>
                            <div class="mb-4 d-flex justify-content-center align-items-center position-relative">

                                <div class="position-absolute  px-3" style={{ "color": "#828282" }}>Already have an account? <spn style={{ color: '#2F9FD9' }}> <Link className="" to="/">Log In</Link> </spn> </div>
                            </div>


                        </div>






                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default ResetPassword;