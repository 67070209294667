import React from "react";
import { useState, useEffect, useRef, useReducer } from 'react';
import { Link } from "react-router-dom";
import { TextBoxComponent, FormValidator } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import styles from "./inbox.module.css";

function InboxFilePassword(props) {
    const [password, setfilePassword] = useState('');

    const handlePasswordClick = () => {
        console.log("teur345")
        console.log(formObjectRef.current.validate(), "form")
        if (formObjectRef.current.validate()) {
            props.handleSecureChange();
            formObjectRef.current.element.reset();
            // dialogInstanceRef.current.show();

        } else {

        }

    };



    const dialogInstanceRef = useRef(null);
    const formObjectRef = useRef(null);
    const animationSettings = { effect: 'Zoom' };

    const floatFocus = (args) => {
        args.target.parentElement.classList.add('e-input-focus');
    };

    const floatBlur = (args) => {
        args.target.parentElement.classList.remove('e-input-focus');
    };

    const onOverlayClick = () => {
        dialogInstanceRef.current.hide();
    };

    useEffect(() => {
        const options = {
            rules: {

                'password': {
                    required: [true, '* Enter your password'],
                },
            }
        };

        formObjectRef.current = new FormValidator('#form1', options);
        // formObjectRef.addRules('password', { maxLength: [6, 'Password at least 6 characters'] });

    }, []);


    return (
        <React.Fragment>
            <div className="container-fluid inboxPasswordText">
                <div>
                    <div className="row mt-1">
                        <div className="col-12"><h6>The File is password protected,enter the password</h6></div>
                    </div>
                    <form id="form1" method="post">
                        <div className="form-group">

                            <div className="row mt-1 " style={{ width: '20%' }}>
                                <div className="col" ><TextBoxComponent type='password' name="password" onFocus={floatFocus} onBlur={floatBlur} placeholder="Enter password" value={password} autocomplete='off' style={{ height: '50px' }} data-msg-containerid="passwordError" /></div>

                            </div>
                            <div className="mt-1" id="passwordError" />

                        </div>


                        <div className="row" style={{ width: '20%' }}>
                            <div className="col">
                                <div className="float-right ">   <Link className="" to="" style={{ color: '#2F9FD9', textDecoration: 'none' }}>Forgot Password ?</Link></div>
                            </div>

                        </div>
                        <div className="row" style={{ width: '20%' }}>
                            <div className="col">
                                <div className="text-center">
                                    <ButtonComponent className="globalbtn p-2" cssClass='e-info' onClick={handlePasswordClick} > Submit</ButtonComponent>
                                </div>
                            </div>
                        </div>
                    </form>


                </div>




















            </div>


        </React.Fragment>
    );
}

export default InboxFilePassword;