import React from "react";
import { useState, useEffect, useRef, useReducer } from 'react';
import { Link } from "react-router-dom";
import { TextBoxComponent, FormValidator } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import Logo from '../../images/dfxFileLogo.png';
import { useHistory } from "react-router-dom";


import styles from "./loginPage.module.css";




function ForGetPassword() {
    const [logEmail, setLogEmail] = useState('');
    const [state, dispatch] = useReducer();

    const history = useHistory();



    const dialogInstanceRef = useRef(null);
    const formObjectRef = useRef(null);
    const animationSettings = { effect: 'Zoom' };

    const floatFocus = (args) => {
        args.target.parentElement.classList.add('e-input-focus');
    };

    const floatBlur = (args) => {
        args.target.parentElement.classList.remove('e-input-focus');
    };

    const onOverlayClick = () => {
        dialogInstanceRef.current.hide();
    };

    useEffect(() => {
        const options = {
            rules: {
                'email': {
                    required: [true, '* Enter your email']
                },

            }
        };

        formObjectRef.current = new FormValidator('#form1', options);
    }, []);

    const onSubmitForget = () => {
        console.log(formObjectRef.current.validate(), "s")
        if (formObjectRef.current.validate()) {
            console.log("trueee")
            history.push("/verifyNumber");
            formObjectRef.current.element.reset();

            // dialogInstanceRef.current.show();

        } else {

        }

    };



    return (
        <React.Fragment>
            <div className="container-fluid logPagehightOverFlow p-0">
                <div className="row m-0">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0">
                        <div className="position-relative">
                            <div className={styles.login_image} >
                                <div className={styles.imgDFXlogo}>
                                    <img src={Logo} style={{ width: '100px' }}></img>
                                    <div className={styles.logInName}>Giving Your <spn style={{ color: '#2F9FD9' }}> Data </spn> the care and Security it needs.</div>


                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">

                        <div className={styles.loginpageTopSet} >
                            <div className={styles.logName}>Forgot Password</div>
                            <form id="form1" method="post">
                                <div className="form-group">
                                    <label className={styles.inputlableNames}>Email</label>
                                    <TextBoxComponent type="email" name="email" value={logEmail} onFocus={floatFocus} onBlur={floatBlur} placeholder="Enter your Email" autocomplete="off" style={{ height: '50px' }} data-msg-containerid="emailError" />
                                    <div className="mt-1" id="emailError" />


                                </div>

                                <div className="mt-5">



                                    <ButtonComponent className={styles.logbtn} onClick={onSubmitForget} cssClass='e-info' >Send Verification Code </ButtonComponent>

                                </div>
                            </form>
                            <div class="mb-4 d-flex justify-content-center align-items-center position-relative">

                                <div class="position-absolute  px-3" style={{ "color": "#828282" }}>Already have an account? <spn style={{ color: '#2F9FD9' }}> <Link className="" to="/">Log In</Link> </spn> </div>
                            </div>


                        </div>






                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default ForGetPassword;