import * as React from "react";
import { ChartComponent, SeriesCollectionDirective, StackingBarSeries, StackingColumnSeries, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel, ColumnSeries, Axis } from '@syncfusion/ej2-react-charts';
import * as ReactDOM from "react-dom";
import styles from "./chart.module.css";


import { useState, useEffect, useRef, useReducer } from 'react';

const stackedChartData = [
    [
        { x: "60", y: 111.1 },
        { x: "120", y: 127.3 },
        { x: "240", y: 143.4 },
        { x: "480", y: 159.9 },
        { x: "960", y: 159.9 },
        { x: "1440", y: 159.9 },
        { x: "1920", y: 159.9 }
    ],
    [
        { x: "60", y: 111.1 },
        { x: "120", y: 127.3 },
        { x: "240", y: 143.4 },
        { x: "480", y: 159.9 },
        { x: "960", y: 159.9 },
        { x: "1440", y: 159.9 },
        { x: "1920", y: 159.9 }
    ],
    [
        { x: "60", y: 111.1 },
        { x: "120", y: 127.3 },
        { x: "240", y: 143.4 },
        { x: "480", y: 159.9 },
        { x: "960", y: 159.9 },
        { x: "1440", y: 159.9 },
        { x: "1920", y: 159.9 }
    ]
];

const stackedCustomSeries = [
    {
        dataSource: stackedChartData[0],
        xName: "x",
        yName: "y",
        name: "Document Expiring Soon",
        type: "StackingColumn",
        fill: "#2F9FD9",
        cornerRadius: {
            bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10
        },
    },

    {
        dataSource: stackedChartData[1],
        xName: "x",
        yName: "y",
        name: "Document Expiry Extended",
        type: "StackingColumn",
        fill: "#115DFC",
        cornerRadius: {
            bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10
        },
    },
    {
        dataSource: stackedChartData[2],
        xName: "x",
        yName: "y",
        name: "Document Expired",
        type: "StackingColumn",
        fill: "#1F1AA8",
        cornerRadius: {
            bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10
        },
    }
];

const stackedPrimaryXAxis = {
    majorGridLines: { width: 0 },
    minorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
    interval: 1,
    lineStyle: { width: 0 },
    labelIntersectAction: "Rotate45",
    valueType: "Category"
};

const stackedPrimaryYAxis = {
    lineStyle: { width: 0 },
    minimum: 100,
    maximum: 400,
    interval: 100,
    majorTickLines: { width: 0 },
    majorGridLines: { width: 1 },
    minorGridLines: { width: 1 },
    minorTickLines: { width: 0 },
    labelFormat: "{value}"
};






function ChartDocumentExpiry() {



    const primaryxAxis = {
        valueType: 'Category',

    };
    const primaryyAxis = { minimum: 0, maximum: 100, interval: 0 };
    const palette = ["#2F9FD9", "#115DFC", "#1F1AA8",];

    // const cornerradius = { bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 };
    return <React.Fragment>
        <div className="row justify-content-start mt-1 mx-3 p-0">
            <h3  className="chartTitle"> Document vs Expiry Period </h3>
        </div>

        <div className="d-flex my-5">
            <div className={styles.dashboardAccessGraph}><span className="ml-1" style={{ borderLeft: '5px solid #2F9FD9', display: 'grid', }}>&nbsp; Document Expiring Soon </span> <span className="text-center"> <h4>10</h4></span> </div>
            <div className={styles.dashboardAccessGraph} ><span style={{ borderLeft: '5px solid #115DFC', display: 'grid', }}> &nbsp;Document Expiry Extended  </span><span className="text-center"> <h4>7</h4></span> </div>
            <div className={styles.dashboardAccessGraph} > <span style={{ borderLeft: '5px solid #1F1AA8', display: 'grid', }}>&nbsp;Document Expired </span><span className="text-center"> <h4>2</h4></span> </div>




        </div>


        <ChartComponent

            id="chartsss"
            primaryXAxis={stackedPrimaryXAxis}
            primaryYAxis={stackedPrimaryYAxis}
            chartArea={{ border: { width: 0 } }}
            tooltip={{ enable: true }}
            legendSettings={{ background: "white", marginTop: "10%" }}
        >
            <Inject services={[Legend, Category, StackingColumnSeries, Tooltip]} />
            <SeriesCollectionDirective>
                {stackedCustomSeries.map((item, index) => (
                    <SeriesDirective key={index} {...item} />
                ))}
            </SeriesCollectionDirective>
        </ChartComponent>
        <div className="row justify-content-center mt-1 mx-3 p-0">
            <h6> Mintues </h6>
        </div>
    </React.Fragment>
}
;
export default ChartDocumentExpiry;