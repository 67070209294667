import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense('Mgo+DSMBaFt/QHRqVVhkVFpHaV1LQmFJfFBmRGNTel56dlVWACFaRnZdQV1hS3ZTdkdkWHtXdHNX;Mgo+DSMBPh8sVXJ0S0J+XE9AflRBQmFAYVF2R2BJfl56cVVMYlxBJAtUQF1hSn5RdE1jWX1ZcXBcQGBe;ORg4AjUWIQA/Gnt2VVhkQlFacldJXnxLd0x0RWFab19wflBGal5RVBYiSV9jS31TdEdrWX9cd3VRT2dfUw==;MTIxOTc1NUAzMjMwMmUzNDJlMzBCckVxR3M2UHBoSUlWWVBROGJPVnZIUWN3M2FyYVcrVVhDVTRlK0pRanZFPQ==;MTIxOTc1NkAzMjMwMmUzNDJlMzBMSWRyNVZ1L0JPbXg3MWpvUkZIVW9NS2cwMGlscjhWeDJqNkYvSjRZdlJRPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmBWfFppR2NbfE5xdF9DYFZTRGY/P1ZhSXxQdkdhUX9ecnNUQ2BeV0Q=;MTIxOTc1OEAzMjMwMmUzNDJlMzBrREx0bFlsTHJJM0p1NEo0NWxIV3ZMSG5kOFdIUm9xN2VpNnJuSzlJakdrPQ==;MTIxOTc1OUAzMjMwMmUzNDJlMzBMK3ZuTVpDdHlvTFB2WGovQ0pYTnViektKdXFkV1N2Y1ZYdHlVK1M5RnBjPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXnxLd0x0RWFab19wflBGal5RVBYiSV9jS31TdEdrWX9cd3VQR2FYVA==;MTIxOTc2MUAzMjMwMmUzNDJlMzBrc2lPYmt5VFdhc2RZa0I5OEJYZm1DMnJzU0JCemtOem5KUVRjN2hCREs4PQ==;MTIxOTc2MkAzMjMwMmUzNDJlMzBvL2dCQjZBQ3ltY2tPTUFpS0lVN2JLYVh0YkFMU3hQV1JacVc2THdGMmFjPQ==;MTIxOTc2M0AzMjMwMmUzNDJlMzBrREx0bFlsTHJJM0p1NEo0NWxIV3ZMSG5kOFdIUm9xN2VpNnJuSzlJakdrPQ==');
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
