import React from 'react';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { select } from '@syncfusion/ej2-base';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { TextAreaComponent } from '@syncfusion/ej2-react-inputs';
import InboxFilePassword from './inboxFilePassword';
import { useState, useEffect, useRef, useReducer } from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import styles from "./inbox.module.css";
import { AiOutlineLeft } from "react-icons/ai"
import { AiOutlineRight } from "react-icons/ai"
import { FaEdit } from "react-icons/fa"
import { FiDownload } from "react-icons/fi"
import { AiOutlineClose } from "react-icons/ai"
import { ImCancelCircle } from "react-icons/im"
import { FiUpload } from "react-icons/fi"
import { IoChatbubblesOutline } from "react-icons/io5"
import DocumentWriter from './Document';
import JPG from '../../images/jpg.png';
import PDF from '../../images/pdf.png';
import Txt from '../../images/txt.png';
import Word from '../../images/word.png';
import Logo from '../../images/dfxFileLogo.png';
import Document from '../../images/document.png';
import PdfViewer from './pdfViewer';
import { Link } from "react-router-dom";
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { Calendar } from '@syncfusion/ej2-calendars';


import './Mydialog.css';






const pdfUrl = `${process.env.PUBLIC_URL}/Documents/sampletest.pdf`;


function InboxBodyContent(mailData) {

    const [uploadDocDialog, setUploadDocDialog] = useState(false);
    const [expiryDialog, setExpiryDialog] = useState(false);
    const [editDocDialog, setEditDocDialog] = useState(false);
    const [uploadDocName, setUploadDocName] = useState();
    const [uploadDescription, setUploadDescription] = useState();
    const [documentSecurity, setDocumentSecurity] = useState();
    const [expireDateDiffDays, setExpireDateDiffDays] = useState();
    const [expiryDateValue, setExpiryDateValue] = useState();
    const calendarIcon = <span className="e-date-icon"></span>;



    const hideUploadDialog = (e) => {
        setUploadDocDialog(false);
    }
    const showUploadDialog = (e) => {
        setUploadDocDialog(true);
    };
    const showExtendExpiryDialog = (e) => {
        setExpiryDialog(true);
    };

    const hideExtendExpiryDialog = (e) => {
        setExpiryDialog(false);
    }

    const showEditDialog = (e) => {
        setEditDocDialog(true)
    }

    const hideEditDialog = (e) => {
        setEditDocDialog(false)

    }



    const handleSecureChange = () => {
        setDocumentSecurity('no');
    };

    useEffect(() => {
        console.log("truee123")
        if (mailData && mailData.mailData.security == 'yes') {
            setDocumentSecurity('yes')
        } else if (mailData && mailData.mailData.security == 'no') {
            setDocumentSecurity('no')

        }

        if (mailData && mailData.mailData.expireDate) {
            let expireDate = new Date(mailData.mailData.expireDate);
            let todayDate = new Date()

            const oneDay = 24 * 60 * 60 * 1000; // number of milliseconds in a day
            const diffInDays = Math.round(Math.abs((expireDate - todayDate) / oneDay)); // calculate the difference in days
            setExpireDateDiffDays(diffInDays)
            console.log(diffInDays, "diffInDays")
        }

    }, [mailData.mailData]);

    // console.log(documentSecurity, "doc")


    function findCharacher(n) {
        const str = n;
        const firstLetter = str.charAt(0);
        const words = str.split(" ");
        const lastWord = words[words.length - 1];
        const lastWordFirstLetter = lastWord.charAt(0);
        let s = firstLetter + lastWordFirstLetter
        return s

    }
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    function dateFormat(date) {
        if (date) {
            var today = new Date(date);
            console.log(today)
            var data = '';
            data = today.getDate() + '-' + monthNames[today.getMonth()] + '-' + today.getFullYear();
            if (data) {
                console.log(data, "data")
                return data;
            } else {
                return data;
            }
        }
    }




    function header() {
        return (<div>
            <section id="mainPopupHeader" >
                <div className="d-flex p-2 text-white">
                    <div >
                        <img src={Document} style={{ width: "25px", marginRight: '10px' }}></img>
                        <span className="mt-1 text-white">  Document  </span>
                    </div>
                </div>
            </section>
        </div>);
    }
    let uploadObj;

    function onClick(args) {
        const wrapperEle = select('.e-file-select-wrap button', document);
        wrapperEle.click();
        args.preventDefault();
    }

    function onFileSelected(args) {
        const inputElement = document.getElementById('upload');
        setUploadDocName(args.filesData[0].name)
        // inputElement.value = ;
    }


    return (
        <React.Fragment>


            {documentSecurity == 'yes' &&
                <div style={{ display: 'block', width: '100%', height: '100vh', background: 'rgba(0,0,0,0.7)', position: 'fixed', zIndex: '1050', color: '#fff' }} >
                    <InboxFilePassword handleSecureChange={handleSecureChange} />
                </div>
            }



            <div className="m-3 mt-4">
                <section id="mailHeader">
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-sm-12 col-md-6 col-lg-8">
                                <div className='d-flex'>
                                    <div className="mt-2">
                                        <h4> <span className={styles.nameLabel}>{findCharacher(mailData.mailData.fromMailName)}</span>  </h4>
                                    </div>
                                    <div className="d-grid pl-1">
                                        <div className={styles.mailHeaderNames}>
                                            <span>{mailData && mailData.mailData.fromMailName}</span> <span> <AiOutlineLeft /> {mailData && mailData.mailData.fromMail} <AiOutlineRight />  </span>
                                        </div>
                                        <div>
                                            <div className={styles.mailHeaderDates}>
                                                <span>{mailData && mailData.mailData.date}</span> ,<span> {mailData && mailData.mailData.time}   </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-1 mt-2">
                                            <h4> <span className={styles.nameLabel}>{findCharacher(mailData.mailData.fromMailName)}</span>  </h4>
                                        </div>
                                        <div className="col-11 d-grid">
                                            <div className={styles.mailHeaderNames}>
                                                <span>{mailData && mailData.mailData.fromMailName}</span> <span> <AiOutlineLeft /> {mailData && mailData.mailData.fromMail} <AiOutlineRight />  </span>
                                            </div>
                                            <div>
                                                <div className={styles.mailHeaderDates}>
                                                    <span>{mailData && mailData.mailData.date}</span> ,<span> {mailData && mailData.mailData.time}   </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div> */}

                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className={styles.inboxActions}>

                                    <div className={styles.inboxIcons}> <Link onClick={showEditDialog} style={{ color: 'black' }}> <FaEdit style={{ color: '#2F9FD9', marginRight: '5px', marginTop: '-3px' }} />  Edit  </Link> </div>
                                    <div className={styles.inboxIcons}>  <Link style={{ color: 'black' }}> <FiDownload style={{ color: '#2F9FD9', marginRight: '5px', marginTop: '-3px' }} /> Download  </Link> </div>
                                    <div className={styles.inboxIcons}> <Link onClick={showUploadDialog} style={{ color: 'black' }}>  <FiUpload style={{ color: '#2F9FD9', marginRight: '5px', marginTop: '-3px' }} /> Upload </Link> </div>
                                    <div className={styles.inboxIcons}> <Link style={{ color: 'black' }}>  <IoChatbubblesOutline style={{ color: '#2F9FD9', marginRight: '5px', marginTop: '-3px' }} /> Chat  </Link> </div>




                                </div>

                            </div>

                        </div>
                        <div className='row mt-1'>
                            <div className='col-12 '>
                                To :  <span>{mailData && mailData.mailData.toMailName}</span> <span> <AiOutlineLeft />{mailData && mailData.mailData.toMail}<AiOutlineRight />  </span>

                            </div>

                        </div>

                    </div>



                </section>

                <section id="mailSubHeader">
                    <div className="container-fluid">
                        <div className='row'>
                            <div className='col-sm-12 col-md-10'>
                                <div className="my-3">
                                    {mailData && mailData.mailData.fileType == 'Word' && <img src={Word} style={{ width: "70px", marginRight: '10px' }}></img>
                                    }
                                    {mailData && mailData.mailData.fileType == 'PDF' && <img src={PDF} style={{ width: "60px", marginRight: '10px' }}></img>
                                    }



                                    <span className={styles.mailSub}>{mailData && mailData.mailData.sub}</span>
                                </div>

                            </div>
                            <div className='col-sm-12 col-md-2'>
                                {expireDateDiffDays < 7 &&
                                    <ButtonComponent className="globalbtn p-2" cssClass='e-info' onClick={showExtendExpiryDialog}  ><FaEdit style={{ marginRight: '5px', marginTop: '-3px' }} /> Extend Expiry? </ButtonComponent>

                                }

                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-12 d-flex'>

                                <div className="px-4 mr-2" style={{ backgroundColor: '#2F83C5', textAlign: 'center' }}>
                                    <span style={{ color: 'white', textAlign: 'center' }}>{mailData && mailData.mailData.subContent}</span>

                                </div>
                                <div className="px-4 ml-2" style={{ backgroundColor: '#ECB73C', textAlign: 'center' }}>
                                    <span style={{ color: 'white', textAlign: 'center' }}>Expires on: {mailData && mailData.mailData.expireDate} , {mailData && mailData.mailData.expireTime}   </span>

                                </div>
                            </div>

                        </div>







                        {/* <div className="row">
                            <div className="my-3">
                                {mailData && mailData.mailData.fileType == 'Word' && <img src={Word} style={{ width: "70px", marginRight: '10px' }}></img>
                                }
                                {mailData && mailData.mailData.fileType == 'PDF' && <img src={PDF} style={{ width: "60px", marginRight: '10px' }}></img>
                                }



                                <span className={styles.mailSub}>{mailData && mailData.mailData.sub}</span>
                            </div>
                            <div className="col-8">
                                <div className="my-3">
                                    {mailData && mailData.mailData.fileType == 'Word' && <img src={Word} style={{ width: "70px", marginRight: '10px' }}></img>
                                    }
                                    {mailData && mailData.mailData.fileType == 'PDF' && <img src={PDF} style={{ width: "60px", marginRight: '10px' }}></img>
                                    }



                                    <span className={styles.mailSub}>{mailData && mailData.mailData.sub}</span>
                                </div>
                                <div className="my-3">
                                    <div className="container-fluid">
                                        <div className="row w-51">
                                            <div className="col-2 mr-2" style={{ backgroundColor: '#2F83C5', textAlign: 'center' }}>
                                                <span style={{ color: 'white', textAlign: 'center' }}>{mailData && mailData.mailData.subContent}</span>

                                            </div>
                                            <div className="col-8 ml-2" style={{ backgroundColor: '#ECB73C', textAlign: 'center' }}>
                                                <span style={{ color: 'white', textAlign: 'center' }}>Expires on: {mailData && mailData.mailData.expireDate} , {mailData && mailData.mailData.expireTime}   </span>

                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div> */}
                    </div>

                </section>
                <section id="mailSubHeader">
                    <div className="container-fluid">
                        <div className="row mt-2">
                            <div className="col-12">
                                <div>
                                    <h6>Reference ID : {mailData && mailData.mailData.refID}</h6>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>




                <section id="disaplyBodyContent">
                    {documentSecurity == 'no' &&
                        <div className='container-fluid'>
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    {mailData && mailData.mailData.fileType == 'Word' && <DocumentWriter></DocumentWriter>}

                                    {mailData && mailData.mailData.fileType == 'PDF' && <PdfViewer></PdfViewer>}




                                </div>

                            </div>

                        </div>
                    }

                </section>



                {/* upload Dialog */}
                <DialogComponent
                    height='100vh'
                    animationSettings={{ effect: 'None' }}
                    visible={uploadDocDialog}
                    // style={{ top: '56px !important' }}
                    showCloseIcon={true}
                    target='body'
                    header={header}
                    open={showUploadDialog}
                    close={hideUploadDialog}



                    // header="head"
                    // content={<p>This is the content of the dialog.</p>}
                    position={{ X: 'right' }}
                    cssClass={styles.dialogModelStyle}

                    onClose={hideUploadDialog}
                >
                    <div>


                        <section id="mainPopupSubHeader">
                            <div className="">
                                <div className="row" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                    {/* <div className="w-100" style={{ boxShadow: '0px 3px 6px #00000029' }}> */}
                                    <div className="col-1">
                                        {/* <img src={Word} style={{ width: "75px", marginRight: '10px' }}></img> */}
                                        {mailData && mailData.mailData.fileType == 'Word' && <img src={Word} style={{ width: "70px", marginRight: '10px' }}></img>
                                        }
                                        {mailData && mailData.mailData.fileType == 'PDF' && <img src={PDF} style={{ width: "60px", marginRight: '10px' }}></img>
                                        }



                                    </div>
                                    <div className="col-6">
                                        <div className="">
                                            <h6>  {mailData && mailData.mailData.sub}</h6>



                                        </div>
                                        <div className="d-flex">
                                            <div className="px-3" style={{ marginRight: '10px', backgroundColor: '#2F83C5', textAlign: 'center' }}>
                                                <span style={{ color: 'white', textAlign: 'center' }}>{mailData && mailData.mailData.subContent}</span>

                                            </div>
                                            <div className="px-3" style={{ backgroundColor: '#A0C1A3', textAlign: 'center' }}>
                                                <span style={{ color: 'white', textAlign: 'center' }}>Expires on: {mailData && mailData.mailData.expireDate} , {mailData && mailData.mailData.expireTime}   </span>

                                            </div>
                                        </div>




                                    </div>

                                    {/* </div> */}


                                </div>

                            </div>


                        </section>
                        <section className='uploadBody'>
                            <div className='container-fluid'>
                                <div className='row mt-5 pl-3'>  <label className='globallabelName'> Upload Document</label></div>

                                <div className='row'>


                                    <div className="col-sm-12 col-md-6 col-lg-10">
                                        {/* <TextBoxComponent /> */}
                                        <TextBoxComponent type="text" name="fileUploadDoc" value={uploadDocName} autocomplete="off" style={{ height: '50px' }} readonly />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-2">
                                        <div className='uploadbtn'>
                                            < UploaderComponent
                                                locale={{ browse: 'Browse' }}
                                                ref={upload => { uploadObj = upload; }} selected={onFileSelected = onFileSelected.bind(this)}

                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className='row'>


                                    <div className='col-12 mt-2'>
                                        <label className='globallabelName' >Upload Summary</label>
                                        <TextBoxComponent name="message" value={uploadDescription} multiline="true" placeholder="Type reason here" />

                                    </div>

                                </div>
                                <div className='row mt-2'>
                                    <div className='col-6 text-right'>
                                        <ButtonComponent className="globalbtnCancel p-2" cssClass='e-success'><ImCancelCircle /> Cancel</ButtonComponent>
                                    </div>
                                    <div className='col-6 text-left'>
                                        <ButtonComponent className="globalbtn p-2" cssClass='e-info' ><FiUpload /> Upload</ButtonComponent>
                                    </div>

                                </div>



                            </div>

                        </section>

                        <img src={Logo} style={{ width: '40px', marginLeft: '18px', position: 'absolute', bottom: '45px' }}></img>


                    </div>
                </DialogComponent>

                {/* expiry Dilalog */}

                <DialogComponent
                    height='100vh'
                    animationSettings={{ effect: 'None' }}
                    visible={expiryDialog}
                    showCloseIcon={true}
                    target='body'
                    header={header}
                    open={showExtendExpiryDialog}
                    close={hideExtendExpiryDialog}
                    position={{ X: 'right' }}
                    cssClass={styles.dialogModelStyle}
                    onClose={hideExtendExpiryDialog}
                >
                    <div>


                        <section id="mainPopupSubHeader">
                            <div className="">
                                <div className="row" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                    {/* <div className="w-100" style={{ boxShadow: '0px 3px 6px #00000029' }}> */}
                                    <div className="col-1">
                                        {/* <img src={Word} style={{ width: "75px", marginRight: '10px' }}></img> */}
                                        {mailData && mailData.mailData.fileType == 'Word' && <img src={Word} style={{ width: "70px", marginRight: '10px' }}></img>
                                        }
                                        {mailData && mailData.mailData.fileType == 'PDF' && <img src={PDF} style={{ width: "60px", marginRight: '10px' }}></img>
                                        }



                                    </div>
                                    <div className="col-6">
                                        <div className="">
                                            <h6>  {mailData && mailData.mailData.sub}</h6>



                                        </div>
                                        <div className="d-flex">
                                            <div className="px-3" style={{ marginRight: '10px', backgroundColor: '#2F83C5', textAlign: 'center' }}>
                                                <span style={{ color: 'white', textAlign: 'center' }}>{mailData && mailData.mailData.subContent}</span>

                                            </div>
                                            <div className="px-3" style={{ backgroundColor: '#A0C1A3', textAlign: 'center' }}>
                                                <span style={{ color: 'white', textAlign: 'center' }}>Expires on: {mailData && mailData.mailData.expireDate} , {mailData && mailData.mailData.expireTime}   </span>

                                            </div>
                                        </div>




                                    </div>

                                    {/* </div> */}


                                </div>

                            </div>


                        </section>
                        <section className='uploadBody'>
                            <div className='container-fluid'>

                                <div className='row mt-5'>
                                    <div className='col-4'>
                                        <lable className="globallabelName">Extend Expiry Date</lable>
                                        <DatePickerComponent id="datepicker" placeholder="dd/mm/yyyy" calendarIcon={calendarIcon}
                                            cssClass="my-date-picker" value={expiryDateValue} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 mt-2'>
                                        <label className='globallabelName' >Request Summary</label>
                                        <TextBoxComponent name="message" value={uploadDescription} multiline="true" placeholder="Type reason here" />
                                    </div>

                                </div>
                                <div className='row mt-2'>
                                    <div className='col-6 text-right'>
                                        <ButtonComponent className="globalbtnCancel p-2" cssClass='e-success'><ImCancelCircle /> Cancel</ButtonComponent>
                                    </div>
                                    <div className='col-6 text-left'>
                                        <ButtonComponent className="globalbtn p-2" cssClass='e-info' ><FiUpload /> Request Changes </ButtonComponent>
                                    </div>

                                </div>



                            </div>

                        </section>

                        <img src={Logo} style={{ width: '40px', marginLeft: '18px', position: 'absolute', bottom: '45px' }}></img>


                    </div>
                </DialogComponent>

                <DialogComponent
                    height='100vh'
                    animationSettings={{ effect: 'None' }}
                    visible={editDocDialog}
                    showCloseIcon={true}
                    target='body'
                    header={header}
                    open={showEditDialog}
                    close={hideEditDialog}
                    position={{ X: 'right' }}
                    cssClass={styles.dialogModelStyle}
                    onClose={hideEditDialog}
                >
                    <div>


                        <section id="mainPopupSubHeader">
                            <div className="">
                                <div className="row" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                    {/* <div className="w-100" style={{ boxShadow: '0px 3px 6px #00000029' }}> */}
                                    <div className="col-1">
                                        {/* <img src={Word} style={{ width: "75px", marginRight: '10px' }}></img> */}
                                        {mailData && mailData.mailData.fileType == 'Word' && <img src={Word} style={{ width: "70px", marginRight: '10px' }}></img>
                                        }
                                        {mailData && mailData.mailData.fileType == 'PDF' && <img src={PDF} style={{ width: "60px", marginRight: '10px' }}></img>
                                        }



                                    </div>
                                    <div className="col-6">
                                        <div className="">
                                            <h6>  {mailData && mailData.mailData.sub}</h6>



                                        </div>
                                        <div className="d-flex">
                                            <div className="px-3" style={{ marginRight: '10px', backgroundColor: '#2F83C5', textAlign: 'center' }}>
                                                <span style={{ color: 'white', textAlign: 'center' }}>{mailData && mailData.mailData.subContent}</span>

                                            </div>
                                            <div className="px-3" style={{ backgroundColor: '#A0C1A3', textAlign: 'center' }}>
                                                <span style={{ color: 'white', textAlign: 'center' }}>Expires on: {mailData && mailData.mailData.expireDate} , {mailData && mailData.mailData.expireTime}   </span>

                                            </div>
                                        </div>




                                    </div>

                                    {/* </div> */}


                                </div>

                            </div>


                        </section>


                        <img src={Logo} style={{ width: '40px', marginLeft: '18px', position: 'absolute', bottom: '45px' }}></img>


                    </div>
                </DialogComponent>




            </div>


        </React.Fragment>
    );
}

export default InboxBodyContent;

