import React from 'react';
import mail from '../../images/mail.png';



function InboxBodyEmptyContent() {

    return (
        <React.Fragment>

            <div className="m-3 mt-4">
                <section id="mailHeader">
                    <div className="container-fluid">
                        <div className='row'>
                            <div className='inboxEmpty'>

                                <img src={mail} style={{ width: "130px" }}></img>
                                <div className='mt-2'> <h5>DFX File Share </h5></div>
                            </div>



                        </div>


                    </div>



                </section>




            </div>


        </React.Fragment>
    );
}

export default InboxBodyEmptyContent;

